@import "partials/params";
@import "partials/mixins";



#category{

	.page-grid{

		
		h1{
			font-weight: 400;
		    font-size: 32px;
		    line-height: 1.94;
		    color: #4f4f4f;
		    font-family: PacificoRegular;
		    text-align: center;
		    @include my-container();
		}

		.products-sort{

			margin-top: 25px;
			
			.sort-content{
				@include my-container();
				@include my-flex;

				.left{
					select#sort-select{
					    box-shadow: inset 0 0 0 1px #e3e3e3;
					    padding: 5px 20px 5px 22px;
					    height: 45px;
					    cursor: pointer;
    					border: none;
    					outline: none;
    					color: #79787f;

    					@media screen and (min-width: 769px) and (max-width: 991px) {
			    	
			    	 		height: 40px;
			    		}
			    		@media screen and (max-width: 768px) {
							height: 35px;
						}
					}

					
				}

				.right{
					.products-view{
							display: inline-block;
							padding: 9px 5px 5px 5px;
							cursor: pointer;
							color: #cdcdce;
						    text-align: center;
						    -webkit-font-smoothing: antialiased;
						    -moz-osx-font-smoothing: grayscale;
						    width: 45px;
						    height: 45px;
						    border: 1px solid $color-green;
						    font-size: 20px;
						    transition: all .7s;

						    @media screen and (min-width: 769px) and (max-width: 991px) {
			    				width: 40px;
			    	 			height: 40px;
			    	 			font-size: 18px;
			    	 			padding: 7px 5px 5px 5px;
			    			}

			    			@media screen and (max-width: 768px) {
								width: 35px;
			    	 			height: 35px;
			    	 			font-size: 14px;
			    	 			padding: 7px 5px 5px 5px;
							}

						    &:hover, &.active-view{
								background: $color-green;
								color: #fff;
						    }
						    &.active-view{
								cursor: default;
						    }
					}

					.grid-products{

						margin-right: 10px;
					}

					.line-products{

					}
				}
			}
		}
		.content{
			@include my-container();
			display: flex;
    		flex-wrap: wrap;
    		justify-content: space-between;
    		margin: 50px auto 0 auto;
			.item{
				text-align: center;
				width: 30%;
				display: flex;
				margin-bottom: 50px;
				border: 1px solid #ccc;
    			padding: 10px 10px 25px 10px;

				@media screen and (max-width: 768px) {
					width: 45%;
				}

				@media screen and (max-width: 500px) {
					width: 90%;
					max-width: 340px;
					margin: 0 auto 50px auto;
				}

    			.item-content{

    				display: flex;
    				flex-direction: column;
    				margin: 0 auto;
					.item-img{
						flex: 1 0 auto;

						a{
							img{
								max-width: 320px;
								width: 100%;

								@media screen and (max-width: 500px) {
									max-width: 250px;
								}
							}
						}
					}

					.item-name{
						font-weight: 400;
					    font-size: 15px;
					    line-height: 1.44;
					    text-align: inherit;
					    color: #333333;
					    margin-bottom: 15px;
					    margin-top: 15px;
					    flex: 1 0 auto;
					    a{
					    	color: #333333;
					    }
					}

					.item-price{
						color: #86ae34;
					    font-style: normal;
					    font-weight: 900;
					    font-size: 20px;
					    line-height: 1.3;
					    margin-bottom: 15px;
					}

					.item-cart{
						button{
		
						}
					}
				}
			}
		}

		.pagination-block{
			@include my-container();
			width: 100%;
			.pagination{
				margin: 35px auto 25px auto;

				@media screen and (min-width: 769px) and (max-width: 991px) {
			    	
			    	margin: 50px auto 25px auto;
			    }

			    @media screen and (max-width: 768px){
					margin: 50px auto 25px auto;
			    }

				text-align: center;
				@include my-flex();
				justify-content: center;
				.nav_active{
					color: #ffffff;
    				background-color: #86ae34;
    				border-color: #86ae34;
    				line-height: 43px;
				    display: inline-block;
				    min-width: 45px;
				    height: 45px;
				    padding: 0 2px;
				    text-align: center;
				    text-decoration: none;
				    -webkit-transition: .3s;
				    -o-transition: .3s;
				    transition: .3s;
				    margin-bottom: 5px;
				    margin-right: 8px;
    				border: 1px solid;
    				font-size: 16px;
					
					 @media screen and (min-width: 769px) and (max-width: 991px) {
			    		line-height: 39px;
				    	min-width: 40px;
				    	height: 40px;
			    	  }
			    	  @media screen and (max-width: 768px){
			    		line-height: 34px;
				    	min-width: 35px;
				    	height: 35px;
			    	  }
    				
				}

				
				a{

					color: #a5a5a5;
    				border-color: #86ae34;
    				line-height: 43px;
				    display: inline-block;
				    min-width: 45px;
				    height: 45px;
				    padding: 0 2px;
				    text-align: center;
				    text-decoration: none;
				    -webkit-transition: .3s;
				    -o-transition: .3s;
				    transition: .3s;
				    margin-bottom: 5px;
    				border: 1px solid;
    				font-size: 16px;


    				@media screen and (min-width: 769px) and (max-width: 991px) {
			    		line-height: 39px;
				    	min-width: 40px;
				    	height: 40px;
			    	  }

			    	  @media screen and (max-width: 768px){
			    		line-height: 34px;
				    	min-width: 35px;
				    	height: 35px;
			    	  }

    				&:hover{
    				   color: #ffffff;
					   background-color: #86ae34;
					   border-color: #86ae34;
    				}
					
					&:not(:last-child){
						margin-right: 8px;
					}
				}
			}
		}
	}

}