@import "partials/params";
@import "partials/mixins";




#registration{
	

	.page-grid{


	.registration-wrap{

		@include my-container();
		h1{

		}

		.registration-anons{
			max-width: 800px;
			margin: 25px auto 0 auto;
			.text-gray{

			}
		}

		.registration-form-block{
			margin-bottom: 50px;
			position: relative;

			.form-success{
				background-color: #f0f0f0;
			    padding: 25px;
			    text-align: center;
			    max-width: 800px;
			    margin: 25px auto;
			}

			form#regForm{
				@include my-flex;
				max-width: 800px;
			    margin: 25px auto;
			    flex-wrap: wrap;
				div{
					width: 45%;
					
					@media screen and (max-width: 500px){
						width: 100%;
   						margin: 0 auto;
					}

					label{
						width: 100%;
						color: $color-form-label;
						font-weight: normal;
					}
					input{
					
					}

					&.reg-btn-block{
						width: 100%;
    					margin-top: 15px;
						
						@media screen and (max-width: 500px){
							text-align: center;
						}
    					    

    					button{
    						height: 45px;
    						width: 45%;

    						@media screen and (max-width: 500px){
								width: 100%;
							}

							@media screen and (max-width: 768px){
							   height: 38px;
    							font-size: 12px;
							}
    						
    					}
					}
				}
			}


			.form-loading{
				display: none;
				position: absolute;
			    top: 0;
			    transform: translate(-50%);
			    left: 50%;
			    width: 100%;
			    background: rgba(240,240,240,0.3);
			    text-align: center;
			    height: 100%;
			    margin: auto;

				#floatBarsG{
					position: relative;
				    width: 234px;
				    top: 50%;
				    height: 28px;
				    margin: auto;
				    transform: translateY(-50%);
				}
			}
		}
	 }
	}
	
}