@mixin my-container(){
	max-width: 1400px;
	margin: 0 auto;
	padding: 0 50px;

	@media screen and (min-width: 769px) and (max-width: 991px) {
		padding-right: 25px;
		padding-left: 25px;
	}

	@media screen and (max-width: 768px) {
		padding-right: 15px;
		padding-left: 15px;
	}
}

@mixin my-flex($horiz-align: space-between, $vert-align: center){
	display: flex;
	justify-content: $horiz-align;
	align-items: $vert-align;
}

@mixin my-clean(){
	padding: 0;
	margin: 0;
}

@mixin overflow-hidden(){
	width: 100%;
	overflow: hidden;
}

@mixin my-links(){
	text-decoration: none;
}

@mixin my-list(){
	list-style: none;
}


@mixin my-text-p(){
	font-family: Lato-Regular;
	font-size: 16px;
	color: #777777;
	line-height: 30px;

}

@mixin my-btn($btn-color: red){
	font-family: Lato-Black;
	color: #10273d;
	font-weight: normal;
	font-size: 16px;
	background: $btn-color;
	border: none;
	outline: none;

	&:active {
		box-shadow: inset 0 0 20px -3px rgba(0, 0, 0, 0.6);
	}
}


@mixin my-input-placeholder(){
	font-size: 18px;
	color: #b5b5b5;
	font-family: PTSansRegular, sans-serif;

	@media screen and (max-width: 768px) {
		font-size: 14px;
	}
}

@mixin my-input(){
	outline: none;
	border: 1px solid #e8e8e8;
}

@mixin container-padd(){
	padding: 50px 0;
}


@mixin my-progress(){
	 height: 20px;
	 border-radius: 4px 0 0 4px;
	 position: relative;
	 background: linear-gradient(to right, #cc2035, #40317f);
}



@mixin my-text-gradient(){
background: -webkit-gradient(linear, left top, right top, from(#cc2035), to(#40317f));
 -webkit-background-clip: text;
 -webkit-text-fill-color: transparent;
 display: initial;

}