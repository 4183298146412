@import "partials/params";
@import "partials/mixins";




#page{
	
	.page-grid{
		
		@include my-container();
		margin: 25px auto 0 auto;

		h1{

		}
		.page-wrap{
			
			margin: 50px auto;

			@media screen and (max-width: 768px){

				margin: 25px auto;
			}


			.content{
				background-color: #F2F3F4;
				@include my-flex();
				flex-wrap: wrap;

				    -webkit-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.45);
				    -moz-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.45);
				    box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.45);

				.left{
					border-right: 1px solid #ccc;
					width: 50%;
					margin: 0;
					@media screen and (max-width: 768px){
						width: 100%;
					    border-right: none;
					    border-bottom: 1px solid #ccc;
					}
					iframe{
						width: 100%;
						height: 350px;
					}
				}

				.right{
					width: 45%;
					margin: 0;

					@media screen and (max-width: 768px){
						width: 100%;
						padding: 50px 25px 25px 25px;
					   
					}
					.block-content{
						
						margin: 0;

						.subblocks{

						    &:not(:last-child){
						    	margin-bottom: 10px;
						    }
						    
						    i{
						    	color: #679509;
						    	font-size: 20px;
						    	margin-right: 10px;

						    	span{
						    		margin-left: 15px;
    								font-family: PTSansRegular, sans-serif;
						    	}
						    }

						    span{
						    	color: #79787f;
								text-decoration: none;
								-webkit-transition: .2s;
							    -o-transition: .2s;
							    transition: .2s;
							    font-size: 15px;
							    font-weight: 300;
							    @media screen and (max-width: 768px){
							    	font-size: 14px;
							    }
						    }
						}
					}
				}
			}

			.page-img{
				max-width: 0%;
			    float: right;
			    padding: 10px 0 25px 25px;

			    @media screen and (max-width: 768px){
					max-width: 100%;
					width: 100%;
					padding: 0 0 25px 0;
				}

				img{
					max-width: 100%;
				}
			}

			.page-text{
					
					.page-img{
						max-width: 40%;
					    background: #fafafa;
					    float: right;
					    margin: 10px 0 35px 35px;
					    padding: 25px;

					    @media screen and (max-width: 768px){
							max-width: 100%;
							width: 100%;
							padding: 25px;
							text-align: center;
						}

						img{
							max-width: 100%;
							@media screen and (max-width: 768px){
								max-width: 400px;
								width: 100%;
							}
						}
					}
					
					div{
						margin-bottom: 35px;

						@media screen and (min-width: 769px) and (max-width: 991px) {
							margin-bottom: 25px;
						}

						@media screen and (max-width: 768px){
								margin-bottom: 25px;
						}
					}
					h2{
						margin-bottom: 15px;
						font-size: 24px;
						@media screen and (min-width: 769px) and (max-width: 991px) {
							margin-bottom: 10px;
    						font-size: 18px;
						}

						@media screen and (max-width: 768px){
							font-size: 18px;
						}
					}

					p{
						font-size: 16px;
	    				line-height: 24px;
	    				margin-bottom: 10px;

	    				@media screen and (min-width: 769px) and (max-width: 991px) {
							    font-size: 14px;
							    line-height: 21px;
							    margin-bottom: 7px;
						}

						@media screen and (max-width: 768px){
							font-size: 14px;
							    line-height: 21px;
							    margin-bottom: 7px;
						}
					}

					ul{
						list-style: disc;
						padding-left: 50px;
						li{
				
						}

					}
			}
		}
	}
}