@import "partials/params";
@import "partials/mixins";




#cart{
	

	.page-grid{


		.cart-wrap{
			@include my-container();
			max-width: 900px;
			margin: 25px auto 0 auto;
			h1{

			}

			.cart-anons{
				margin: 25px auto 0 auto;
			}

			.cart-items{
				margin: 35px auto 0 auto;
					
				.cart-items-title{
					font-size: 18px;
    				text-align: center;
    				margin: 0 0 15px 0;
				}
				
				table{
					width: 100%;
				    text-align: center;
				    border-top: 1px solid #ccc;
				    border-bottom: 1px solid #ccc;

				    @media screen and (max-width: 500px){
						font-size: 12px;
					}

					tr{
						td{
							text-align: center;
							min-width: 70px;

							@media screen and (max-width: 500px){
								padding: 10px 5px;
							}
							

							&.cart-item-photo{
									@media screen and (max-width: 500px){
										display: none;
									}
							}

							&.cart-item-name{

								padding-right: 10px;
								padding-left: 10px;

								@media screen and (max-width: 500px){
									text-align: left;
								}
							}

							&.cart-item-price{
								
							}

							img{
								width: 150px;
								margin: 5px;

								@media screen and (max-width: 768px) {
									width: 70px;
								}
							}

							.del-cart-item{
								margin: 0 0 0 15px;
								cursor: pointer;
								transition: all .7s;
								font-size: 18px;

								&:hover{
									color: #679509;
								}
							}    
						}
						th{
							text-align: center;
							padding: 15px 5px 10px 5px;
							background: #f0f0f0;

							@media screen and (max-width: 500px){
								font-size: 10px;
							}

							&.cart-item-photo{
								@media screen and (max-width: 500px){
									display: none;
								}
							}
							&.cart-item-name{
									@media screen and (max-width: 500px){
										text-align: left;
									}
							}
						}
					}
					
				}
				
				.cart-items-total{

				    text-align: right;
				    font-size: 18px;
    				margin: 10px 5px 0 0;
				}

			}

			
			.cart-form{

				    margin: 35px auto auto 0;
					
					#myTabOrder{
						border-bottom: none;
						li{
							background: #f0f0f0;
				    		color: #000;
				    		margin-bottom: 0;
							
							&:first-child{
								margin-right: 25px;

								@media screen and (max-width: 500px) {
									margin-right: 0px;
								}
							}

							&.active{
								
								a{
								    border-radius: 0;
								    background: #86ae34;
								    color: #fff;

								    @media screen and (max-width: 500px) {
									    padding: 10px 7px;
									}
				    			}
							}

				    		a{
								color: #000;
								@media screen and (max-width: 500px) {
									    padding: 10px 7px;
								}
				    		}
						}
					
					}
					

					#order-quick{
						padding: 50px 0 0 0;

						@media screen and (max-width: 500px) {
							padding: 25px 0 0 0;
						}

						.order-form{
							
							&:not(:last-child){
								margin: 0 0 35px 0;
							}

							.order-form-title{
								margin-bottom: 15px;
							}

							#order-quick-form{
								input{
									width: 50%;

									@media screen and (max-width: 500px) {
										width: 100%;
									}
								}
								label{
									width: 100%;
									color: $color-form-label;
									font-weight: normal;
								}
								
								button{
									@media screen and (max-width: 500px) {
										width: 100%;
									}
								}
								
							}
						}
					}
				    

				    #order-registr{
						padding: 50px 0 0 0;

						@media screen and (max-width: 500px) {
							padding: 25px 0 0 0;
						}
						
						.order-form{
							
							&:not(:last-child){
								margin: 0 0 35px 0;
							}

							.order-form-title{
								margin-bottom: 15px;
							}

							.order-delivery{
								select{
									height: 47px;
								    cursor: pointer;
								    border-radius: 3px;
								    padding: 0 25px 0 5px;
								    outline: none;
								    font-size: 14px;
								    border: 1px solid #ccc;

								    @media screen and (max-width: 768px) {
										height: 40px;
									}

									@media screen and (max-width: 500px) {
										width: 100%;
									}

									
								}


							}



							.order-registration{


								form#order-reg-form{
										@include my-flex;
										max-width: 900px;
									    margin: 0 auto;
									    flex-wrap: wrap;
										div{
											width: 45%;

											&.prim-block{
												width: 100%;
											}
											
											@media screen and (max-width: 500px){
												width: 100%;
						   						margin: 0 auto;
											}

											label{
												width: 100%;
												color: $color-form-label;
												font-weight: normal;
											}
											input{
											
											}

											textarea{
											    padding: 10px 20px;
											    background-color: #fff;
											    border-color: #c9cdcf;
											    border-radius: 3px;
											    color: #9ba0a3;
											    width: 100%;
											    resize: none;
											    height: 100px;
											    margin-bottom: 15px;
											    font-size: 18px;
											    outline: none;
											}

											&.reg-btn-block{
												width: 100%;
						    					margin-top: 15px;
												
												@media screen and (max-width: 500px){
													text-align: center;
												}
						    					    

						    					button{
						    						height: 45px;
						    						width: 45%;

						    						@media screen and (max-width: 500px){
														width: 100%;
													}

													@media screen and (max-width: 768px){
													   height: 38px;
						    							font-size: 14px;
													}
						    						
						    					}
											}
										}
									}

							} /* .order-registration */


						}
				    }


				}
		}
	}	
}