@import "partials/params";
@import "partials/mixins";




#article{
	
	.page-grid{
		
		@include my-container();
		margin: 25px auto 0 auto;

		h1{

		}
		.article-wrap{
			
			margin: 50px auto;

			@media screen and (max-width: 768px){

				margin: 25px auto;
			}

			.article-img{
				
				max-width: 60%;
			    float: right;
			    padding: 10px 0 25px 25px;

			    @media screen and (max-width: 768px){
					max-width: 100%;
					width: 100%;
					padding: 0 0 25px 0;
				}

				img{
					max-width: 100%;
				}
			}

			.article-text{
				
					
					div{
						margin-bottom: 35px;

						@media screen and (min-width: 769px) and (max-width: 991px) {
							margin-bottom: 25px;
						}

						@media screen and (max-width: 768px){
								margin-bottom: 25px;
						}
					}
					h2{
						margin-bottom: 15px;
						font-size: 24px;
						@media screen and (min-width: 769px) and (max-width: 991px) {
							margin-bottom: 10px;
    						font-size: 18px;
						}

						@media screen and (max-width: 768px){
							font-size: 18px;
						}
					}

					p{
						font-size: 16px;
	    				line-height: 24px;
	    				margin-bottom: 10px;

	    				@media screen and (min-width: 769px) and (max-width: 991px) {
							    font-size: 14px;
							    line-height: 21px;
							    margin-bottom: 7px;
						}

						@media screen and (max-width: 768px){
							font-size: 14px;
							    line-height: 21px;
							    margin-bottom: 7px;
						}
					}

					ul{
						list-style: disc;
						padding-left: 50px;
						li{
				
						}

					}

					table{
						border: 1px solid #ccc;
						margin: 25px auto;
    					text-align: center;
    					border-collapse: separate;
						tr{
							th{
								padding: 10px 25px;
    							background: #f0f0f0;
							}

							td{
								padding: 8px;
    							border-bottom: 1px solid #ccc;
							}
						}

					}
			}
		}
	}
}