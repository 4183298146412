#page-not-found .page-grid {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 50px;
  margin: 25px auto 0 auto; }
  @media screen and (min-width: 769px) and (max-width: 991px) {
    #page-not-found .page-grid {
      padding-right: 25px;
      padding-left: 25px; } }
  @media screen and (max-width: 768px) {
    #page-not-found .page-grid {
      padding-right: 15px;
      padding-left: 15px; } }
  #page-not-found .page-grid .page-not-found-wrap {
    margin: 50px auto; }
    @media screen and (max-width: 768px) {
      #page-not-found .page-grid .page-not-found-wrap {
        margin: 25px auto; } }
    #page-not-found .page-grid .page-not-found-wrap .page-not-found-text .page-img {
      max-width: 40%;
      background: #fafafa;
      float: right;
      margin: 10px 0 35px 35px;
      padding: 25px; }
      @media screen and (max-width: 768px) {
        #page-not-found .page-grid .page-not-found-wrap .page-not-found-text .page-img {
          max-width: 100%;
          width: 100%;
          padding: 25px;
          text-align: center; } }
      #page-not-found .page-grid .page-not-found-wrap .page-not-found-text .page-img img {
        max-width: 100%; }
        @media screen and (max-width: 768px) {
          #page-not-found .page-grid .page-not-found-wrap .page-not-found-text .page-img img {
            max-width: 400px;
            width: 100%; } }
    #page-not-found .page-grid .page-not-found-wrap .page-not-found-text div {
      margin-top: 35px;
      text-align: center; }
      @media screen and (min-width: 769px) and (max-width: 991px) {
        #page-not-found .page-grid .page-not-found-wrap .page-not-found-text div {
          margin-bottom: 25px; } }
      @media screen and (max-width: 768px) {
        #page-not-found .page-grid .page-not-found-wrap .page-not-found-text div {
          margin-bottom: 25px; } }
    #page-not-found .page-grid .page-not-found-wrap .page-not-found-text h2 {
      margin-bottom: 15px;
      font-size: 24px; }
      @media screen and (min-width: 769px) and (max-width: 991px) {
        #page-not-found .page-grid .page-not-found-wrap .page-not-found-text h2 {
          margin-bottom: 10px;
          font-size: 18px; } }
      @media screen and (max-width: 768px) {
        #page-not-found .page-grid .page-not-found-wrap .page-not-found-text h2 {
          font-size: 18px; } }
    #page-not-found .page-grid .page-not-found-wrap .page-not-found-text p {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px;
      text-align: center; }
      @media screen and (min-width: 769px) and (max-width: 991px) {
        #page-not-found .page-grid .page-not-found-wrap .page-not-found-text p {
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 7px; } }
      @media screen and (max-width: 768px) {
        #page-not-found .page-grid .page-not-found-wrap .page-not-found-text p {
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 7px; } }
    #page-not-found .page-grid .page-not-found-wrap .page-not-found-text ul {
      list-style: disc;
      padding-left: 50px; }

#article .page-grid {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 50px;
  margin: 25px auto 0 auto; }
  @media screen and (min-width: 769px) and (max-width: 991px) {
    #article .page-grid {
      padding-right: 25px;
      padding-left: 25px; } }
  @media screen and (max-width: 768px) {
    #article .page-grid {
      padding-right: 15px;
      padding-left: 15px; } }
  #article .page-grid .article-wrap {
    margin: 50px auto; }
    @media screen and (max-width: 768px) {
      #article .page-grid .article-wrap {
        margin: 25px auto; } }
    #article .page-grid .article-wrap .article-img {
      max-width: 60%;
      float: right;
      padding: 10px 0 25px 25px; }
      @media screen and (max-width: 768px) {
        #article .page-grid .article-wrap .article-img {
          max-width: 100%;
          width: 100%;
          padding: 0 0 25px 0; } }
      #article .page-grid .article-wrap .article-img img {
        max-width: 100%; }
    #article .page-grid .article-wrap .article-text div {
      margin-bottom: 35px; }
      @media screen and (min-width: 769px) and (max-width: 991px) {
        #article .page-grid .article-wrap .article-text div {
          margin-bottom: 25px; } }
      @media screen and (max-width: 768px) {
        #article .page-grid .article-wrap .article-text div {
          margin-bottom: 25px; } }
    #article .page-grid .article-wrap .article-text h2 {
      margin-bottom: 15px;
      font-size: 24px; }
      @media screen and (min-width: 769px) and (max-width: 991px) {
        #article .page-grid .article-wrap .article-text h2 {
          margin-bottom: 10px;
          font-size: 18px; } }
      @media screen and (max-width: 768px) {
        #article .page-grid .article-wrap .article-text h2 {
          font-size: 18px; } }
    #article .page-grid .article-wrap .article-text p {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px; }
      @media screen and (min-width: 769px) and (max-width: 991px) {
        #article .page-grid .article-wrap .article-text p {
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 7px; } }
      @media screen and (max-width: 768px) {
        #article .page-grid .article-wrap .article-text p {
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 7px; } }
    #article .page-grid .article-wrap .article-text ul {
      list-style: disc;
      padding-left: 50px; }
    #article .page-grid .article-wrap .article-text table {
      border: 1px solid #ccc;
      margin: 25px auto;
      text-align: center;
      border-collapse: separate; }
      #article .page-grid .article-wrap .article-text table tr th {
        padding: 10px 25px;
        background: #f0f0f0; }
      #article .page-grid .article-wrap .article-text table tr td {
        padding: 8px;
        border-bottom: 1px solid #ccc; }

#blog .page-grid {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 50px;
  margin: 25px auto 0 auto; }
  @media screen and (min-width: 769px) and (max-width: 991px) {
    #blog .page-grid {
      padding-right: 25px;
      padding-left: 25px; } }
  @media screen and (max-width: 768px) {
    #blog .page-grid {
      padding-right: 15px;
      padding-left: 15px; } }
  #blog .page-grid .blog-anons {
    max-width: 800px;
    margin: 25px auto 0 auto; }
    @media screen and (max-width: 768px) {
      #blog .page-grid .blog-anons {
        margin: 10px auto 0 auto; } }
  #blog .page-grid .blog-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-items: unset;
    flex-wrap: wrap;
    margin: 50px auto; }
    #blog .page-grid .blog-wrap .blog-item {
      width: 30%;
      display: flex;
      margin-bottom: 50px; }
      #blog .page-grid .blog-wrap .blog-item .blog-item-content {
        display: flex;
        flex-direction: column; }
        #blog .page-grid .blog-wrap .blog-item .blog-item-content .blog-img {
          margin-bottom: 10px; }
          #blog .page-grid .blog-wrap .blog-item .blog-item-content .blog-img img {
            max-width: 100%; }
        #blog .page-grid .blog-wrap .blog-item .blog-item-content .blog-date {
          margin-bottom: 15px; }
          #blog .page-grid .blog-wrap .blog-item .blog-item-content .blog-date span {
            color: #79787f;
            font-weight: bold; }
        #blog .page-grid .blog-wrap .blog-item .blog-item-content .blog-title {
          margin-bottom: 10px; }
          #blog .page-grid .blog-wrap .blog-item .blog-item-content .blog-title a {
            font-size: 18px;
            font-weight: 600;
            color: #4f4f4f; }
            @media screen and (max-width: 768px) {
              #blog .page-grid .blog-wrap .blog-item .blog-item-content .blog-title a {
                font-size: 16px; } }
            #blog .page-grid .blog-wrap .blog-item .blog-item-content .blog-title a:hover {
              color: #86ae34; }
        #blog .page-grid .blog-wrap .blog-item .blog-item-content .blog-text {
          color: #8b8b8b;
          margin-bottom: 20px;
          font-size: 16px; }
          @media screen and (max-width: 768px) {
            #blog .page-grid .blog-wrap .blog-item .blog-item-content .blog-text {
              font-size: 14px; } }
        #blog .page-grid .blog-wrap .blog-item .blog-item-content .blog-more button {
          background-color: #86ae34;
          color: #fff;
          width: 160px;
          font-weight: 400;
          font-size: 16px;
          line-height: 1.75;
          transition: all .7s;
          height: 32px; }
          @media screen and (max-width: 768px) {
            #blog .page-grid .blog-wrap .blog-item .blog-item-content .blog-more button {
              font-size: 14px;
              width: 140px;
              height: 30px; } }
          #blog .page-grid .blog-wrap .blog-item .blog-item-content .blog-more button:hover {
            background-color: #1e1d24; }
      @media screen and (max-width: 991px) {
        #blog .page-grid .blog-wrap .blog-item {
          width: 45%; } }
      @media screen and (max-width: 500px) {
        #blog .page-grid .blog-wrap .blog-item {
          width: 100%; } }

#cart .page-grid .cart-wrap {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 50px;
  max-width: 900px;
  margin: 25px auto 0 auto; }
  @media screen and (min-width: 769px) and (max-width: 991px) {
    #cart .page-grid .cart-wrap {
      padding-right: 25px;
      padding-left: 25px; } }
  @media screen and (max-width: 768px) {
    #cart .page-grid .cart-wrap {
      padding-right: 15px;
      padding-left: 15px; } }
  #cart .page-grid .cart-wrap .cart-anons {
    margin: 25px auto 0 auto; }
  #cart .page-grid .cart-wrap .cart-items {
    margin: 35px auto 0 auto; }
    #cart .page-grid .cart-wrap .cart-items .cart-items-title {
      font-size: 18px;
      text-align: center;
      margin: 0 0 15px 0; }
    #cart .page-grid .cart-wrap .cart-items table {
      width: 100%;
      text-align: center;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc; }
      @media screen and (max-width: 500px) {
        #cart .page-grid .cart-wrap .cart-items table {
          font-size: 12px; } }
      #cart .page-grid .cart-wrap .cart-items table tr td {
        text-align: center;
        min-width: 70px; }
        @media screen and (max-width: 500px) {
          #cart .page-grid .cart-wrap .cart-items table tr td {
            padding: 10px 5px; } }
        @media screen and (max-width: 500px) {
          #cart .page-grid .cart-wrap .cart-items table tr td.cart-item-photo {
            display: none; } }
        #cart .page-grid .cart-wrap .cart-items table tr td.cart-item-name {
          padding-right: 10px;
          padding-left: 10px; }
          @media screen and (max-width: 500px) {
            #cart .page-grid .cart-wrap .cart-items table tr td.cart-item-name {
              text-align: left; } }
        #cart .page-grid .cart-wrap .cart-items table tr td img {
          width: 150px;
          margin: 5px; }
          @media screen and (max-width: 768px) {
            #cart .page-grid .cart-wrap .cart-items table tr td img {
              width: 70px; } }
        #cart .page-grid .cart-wrap .cart-items table tr td .del-cart-item {
          margin: 0 0 0 15px;
          cursor: pointer;
          transition: all .7s;
          font-size: 18px; }
          #cart .page-grid .cart-wrap .cart-items table tr td .del-cart-item:hover {
            color: #679509; }
      #cart .page-grid .cart-wrap .cart-items table tr th {
        text-align: center;
        padding: 15px 5px 10px 5px;
        background: #f0f0f0; }
        @media screen and (max-width: 500px) {
          #cart .page-grid .cart-wrap .cart-items table tr th {
            font-size: 10px; } }
        @media screen and (max-width: 500px) {
          #cart .page-grid .cart-wrap .cart-items table tr th.cart-item-photo {
            display: none; } }
        @media screen and (max-width: 500px) {
          #cart .page-grid .cart-wrap .cart-items table tr th.cart-item-name {
            text-align: left; } }
    #cart .page-grid .cart-wrap .cart-items .cart-items-total {
      text-align: right;
      font-size: 18px;
      margin: 10px 5px 0 0; }
  #cart .page-grid .cart-wrap .cart-form {
    margin: 35px auto auto 0; }
    #cart .page-grid .cart-wrap .cart-form #myTabOrder {
      border-bottom: none; }
      #cart .page-grid .cart-wrap .cart-form #myTabOrder li {
        background: #f0f0f0;
        color: #000;
        margin-bottom: 0; }
        #cart .page-grid .cart-wrap .cart-form #myTabOrder li:first-child {
          margin-right: 25px; }
          @media screen and (max-width: 500px) {
            #cart .page-grid .cart-wrap .cart-form #myTabOrder li:first-child {
              margin-right: 0px; } }
        #cart .page-grid .cart-wrap .cart-form #myTabOrder li.active a {
          border-radius: 0;
          background: #86ae34;
          color: #fff; }
          @media screen and (max-width: 500px) {
            #cart .page-grid .cart-wrap .cart-form #myTabOrder li.active a {
              padding: 10px 7px; } }
        #cart .page-grid .cart-wrap .cart-form #myTabOrder li a {
          color: #000; }
          @media screen and (max-width: 500px) {
            #cart .page-grid .cart-wrap .cart-form #myTabOrder li a {
              padding: 10px 7px; } }
    #cart .page-grid .cart-wrap .cart-form #order-quick {
      padding: 50px 0 0 0; }
      @media screen and (max-width: 500px) {
        #cart .page-grid .cart-wrap .cart-form #order-quick {
          padding: 25px 0 0 0; } }
      #cart .page-grid .cart-wrap .cart-form #order-quick .order-form:not(:last-child) {
        margin: 0 0 35px 0; }
      #cart .page-grid .cart-wrap .cart-form #order-quick .order-form .order-form-title {
        margin-bottom: 15px; }
      #cart .page-grid .cart-wrap .cart-form #order-quick .order-form #order-quick-form input {
        width: 50%; }
        @media screen and (max-width: 500px) {
          #cart .page-grid .cart-wrap .cart-form #order-quick .order-form #order-quick-form input {
            width: 100%; } }
      #cart .page-grid .cart-wrap .cart-form #order-quick .order-form #order-quick-form label {
        width: 100%;
        color: #505050;
        font-weight: normal; }
      @media screen and (max-width: 500px) {
        #cart .page-grid .cart-wrap .cart-form #order-quick .order-form #order-quick-form button {
          width: 100%; } }
    #cart .page-grid .cart-wrap .cart-form #order-registr {
      padding: 50px 0 0 0; }
      @media screen and (max-width: 500px) {
        #cart .page-grid .cart-wrap .cart-form #order-registr {
          padding: 25px 0 0 0; } }
      #cart .page-grid .cart-wrap .cart-form #order-registr .order-form {
        /* .order-registration */ }
        #cart .page-grid .cart-wrap .cart-form #order-registr .order-form:not(:last-child) {
          margin: 0 0 35px 0; }
        #cart .page-grid .cart-wrap .cart-form #order-registr .order-form .order-form-title {
          margin-bottom: 15px; }
        #cart .page-grid .cart-wrap .cart-form #order-registr .order-form .order-delivery select {
          height: 47px;
          cursor: pointer;
          border-radius: 3px;
          padding: 0 25px 0 5px;
          outline: none;
          font-size: 14px;
          border: 1px solid #ccc; }
          @media screen and (max-width: 768px) {
            #cart .page-grid .cart-wrap .cart-form #order-registr .order-form .order-delivery select {
              height: 40px; } }
          @media screen and (max-width: 500px) {
            #cart .page-grid .cart-wrap .cart-form #order-registr .order-form .order-delivery select {
              width: 100%; } }
        #cart .page-grid .cart-wrap .cart-form #order-registr .order-form .order-registration form#order-reg-form {
          display: flex;
          justify-content: space-between;
          align-items: center;
          max-width: 900px;
          margin: 0 auto;
          flex-wrap: wrap; }
          #cart .page-grid .cart-wrap .cart-form #order-registr .order-form .order-registration form#order-reg-form div {
            width: 45%; }
            #cart .page-grid .cart-wrap .cart-form #order-registr .order-form .order-registration form#order-reg-form div.prim-block {
              width: 100%; }
            @media screen and (max-width: 500px) {
              #cart .page-grid .cart-wrap .cart-form #order-registr .order-form .order-registration form#order-reg-form div {
                width: 100%;
                margin: 0 auto; } }
            #cart .page-grid .cart-wrap .cart-form #order-registr .order-form .order-registration form#order-reg-form div label {
              width: 100%;
              color: #505050;
              font-weight: normal; }
            #cart .page-grid .cart-wrap .cart-form #order-registr .order-form .order-registration form#order-reg-form div textarea {
              padding: 10px 20px;
              background-color: #fff;
              border-color: #c9cdcf;
              border-radius: 3px;
              color: #9ba0a3;
              width: 100%;
              resize: none;
              height: 100px;
              margin-bottom: 15px;
              font-size: 18px;
              outline: none; }
            #cart .page-grid .cart-wrap .cart-form #order-registr .order-form .order-registration form#order-reg-form div.reg-btn-block {
              width: 100%;
              margin-top: 15px; }
              @media screen and (max-width: 500px) {
                #cart .page-grid .cart-wrap .cart-form #order-registr .order-form .order-registration form#order-reg-form div.reg-btn-block {
                  text-align: center; } }
              #cart .page-grid .cart-wrap .cart-form #order-registr .order-form .order-registration form#order-reg-form div.reg-btn-block button {
                height: 45px;
                width: 45%; }
                @media screen and (max-width: 500px) {
                  #cart .page-grid .cart-wrap .cart-form #order-registr .order-form .order-registration form#order-reg-form div.reg-btn-block button {
                    width: 100%; } }
                @media screen and (max-width: 768px) {
                  #cart .page-grid .cart-wrap .cart-form #order-registr .order-form .order-registration form#order-reg-form div.reg-btn-block button {
                    height: 38px;
                    font-size: 14px; } }

#category .page-grid h1 {
  font-weight: 400;
  font-size: 32px;
  line-height: 1.94;
  color: #4f4f4f;
  font-family: PacificoRegular;
  text-align: center;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 50px; }
  @media screen and (min-width: 769px) and (max-width: 991px) {
    #category .page-grid h1 {
      padding-right: 25px;
      padding-left: 25px; } }
  @media screen and (max-width: 768px) {
    #category .page-grid h1 {
      padding-right: 15px;
      padding-left: 15px; } }

#category .page-grid .products-sort {
  margin-top: 25px; }
  #category .page-grid .products-sort .sort-content {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 50px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media screen and (min-width: 769px) and (max-width: 991px) {
      #category .page-grid .products-sort .sort-content {
        padding-right: 25px;
        padding-left: 25px; } }
    @media screen and (max-width: 768px) {
      #category .page-grid .products-sort .sort-content {
        padding-right: 15px;
        padding-left: 15px; } }
    #category .page-grid .products-sort .sort-content .left select#sort-select {
      box-shadow: inset 0 0 0 1px #e3e3e3;
      padding: 5px 20px 5px 22px;
      height: 45px;
      cursor: pointer;
      border: none;
      outline: none;
      color: #79787f; }
      @media screen and (min-width: 769px) and (max-width: 991px) {
        #category .page-grid .products-sort .sort-content .left select#sort-select {
          height: 40px; } }
      @media screen and (max-width: 768px) {
        #category .page-grid .products-sort .sort-content .left select#sort-select {
          height: 35px; } }
    #category .page-grid .products-sort .sort-content .right .products-view {
      display: inline-block;
      padding: 9px 5px 5px 5px;
      cursor: pointer;
      color: #cdcdce;
      text-align: center;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      width: 45px;
      height: 45px;
      border: 1px solid #86ae34;
      font-size: 20px;
      transition: all .7s; }
      @media screen and (min-width: 769px) and (max-width: 991px) {
        #category .page-grid .products-sort .sort-content .right .products-view {
          width: 40px;
          height: 40px;
          font-size: 18px;
          padding: 7px 5px 5px 5px; } }
      @media screen and (max-width: 768px) {
        #category .page-grid .products-sort .sort-content .right .products-view {
          width: 35px;
          height: 35px;
          font-size: 14px;
          padding: 7px 5px 5px 5px; } }
      #category .page-grid .products-sort .sort-content .right .products-view:hover, #category .page-grid .products-sort .sort-content .right .products-view.active-view {
        background: #86ae34;
        color: #fff; }
      #category .page-grid .products-sort .sort-content .right .products-view.active-view {
        cursor: default; }
    #category .page-grid .products-sort .sort-content .right .grid-products {
      margin-right: 10px; }

#category .page-grid .content {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 50px auto 0 auto; }
  @media screen and (min-width: 769px) and (max-width: 991px) {
    #category .page-grid .content {
      padding-right: 25px;
      padding-left: 25px; } }
  @media screen and (max-width: 768px) {
    #category .page-grid .content {
      padding-right: 15px;
      padding-left: 15px; } }
  #category .page-grid .content .item {
    text-align: center;
    width: 30%;
    display: flex;
    margin-bottom: 50px;
    border: 1px solid #ccc;
    padding: 10px 10px 25px 10px; }
    @media screen and (max-width: 768px) {
      #category .page-grid .content .item {
        width: 45%; } }
    @media screen and (max-width: 500px) {
      #category .page-grid .content .item {
        width: 90%;
        max-width: 340px;
        margin: 0 auto 50px auto; } }
    #category .page-grid .content .item .item-content {
      display: flex;
      flex-direction: column;
      margin: 0 auto; }
      #category .page-grid .content .item .item-content .item-img {
        flex: 1 0 auto; }
        #category .page-grid .content .item .item-content .item-img a img {
          max-width: 320px;
          width: 100%; }
          @media screen and (max-width: 500px) {
            #category .page-grid .content .item .item-content .item-img a img {
              max-width: 250px; } }
      #category .page-grid .content .item .item-content .item-name {
        font-weight: 400;
        font-size: 15px;
        line-height: 1.44;
        text-align: inherit;
        color: #333333;
        margin-bottom: 15px;
        margin-top: 15px;
        flex: 1 0 auto; }
        #category .page-grid .content .item .item-content .item-name a {
          color: #333333; }
      #category .page-grid .content .item .item-content .item-price {
        color: #86ae34;
        font-style: normal;
        font-weight: 900;
        font-size: 20px;
        line-height: 1.3;
        margin-bottom: 15px; }

#category .page-grid .pagination-block {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 50px;
  width: 100%; }
  @media screen and (min-width: 769px) and (max-width: 991px) {
    #category .page-grid .pagination-block {
      padding-right: 25px;
      padding-left: 25px; } }
  @media screen and (max-width: 768px) {
    #category .page-grid .pagination-block {
      padding-right: 15px;
      padding-left: 15px; } }
  #category .page-grid .pagination-block .pagination {
    margin: 35px auto 25px auto;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center; }
    @media screen and (min-width: 769px) and (max-width: 991px) {
      #category .page-grid .pagination-block .pagination {
        margin: 50px auto 25px auto; } }
    @media screen and (max-width: 768px) {
      #category .page-grid .pagination-block .pagination {
        margin: 50px auto 25px auto; } }
    #category .page-grid .pagination-block .pagination .nav_active {
      color: #ffffff;
      background-color: #86ae34;
      border-color: #86ae34;
      line-height: 43px;
      display: inline-block;
      min-width: 45px;
      height: 45px;
      padding: 0 2px;
      text-align: center;
      text-decoration: none;
      -webkit-transition: .3s;
      -o-transition: .3s;
      transition: .3s;
      margin-bottom: 5px;
      margin-right: 8px;
      border: 1px solid;
      font-size: 16px; }
      @media screen and (min-width: 769px) and (max-width: 991px) {
        #category .page-grid .pagination-block .pagination .nav_active {
          line-height: 39px;
          min-width: 40px;
          height: 40px; } }
      @media screen and (max-width: 768px) {
        #category .page-grid .pagination-block .pagination .nav_active {
          line-height: 34px;
          min-width: 35px;
          height: 35px; } }
    #category .page-grid .pagination-block .pagination a {
      color: #a5a5a5;
      border-color: #86ae34;
      line-height: 43px;
      display: inline-block;
      min-width: 45px;
      height: 45px;
      padding: 0 2px;
      text-align: center;
      text-decoration: none;
      -webkit-transition: .3s;
      -o-transition: .3s;
      transition: .3s;
      margin-bottom: 5px;
      border: 1px solid;
      font-size: 16px; }
      @media screen and (min-width: 769px) and (max-width: 991px) {
        #category .page-grid .pagination-block .pagination a {
          line-height: 39px;
          min-width: 40px;
          height: 40px; } }
      @media screen and (max-width: 768px) {
        #category .page-grid .pagination-block .pagination a {
          line-height: 34px;
          min-width: 35px;
          height: 35px; } }
      #category .page-grid .pagination-block .pagination a:hover {
        color: #ffffff;
        background-color: #86ae34;
        border-color: #86ae34; }
      #category .page-grid .pagination-block .pagination a:not(:last-child) {
        margin-right: 8px; }

#footer {
  background: url(images/footer-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 150px 0 50px 0; }
  @media screen and (max-width: 768px) {
    #footer {
      padding: 100px 0 25px 0; } }
  #footer .content {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    align-items: baseline; }
    @media screen and (min-width: 769px) and (max-width: 991px) {
      #footer .content {
        padding-right: 25px;
        padding-left: 25px; } }
    @media screen and (max-width: 768px) {
      #footer .content {
        padding-right: 15px;
        padding-left: 15px; } }
    #footer .content .block {
      width: 30%; }
      @media screen and (max-width: 768px) {
        #footer .content .block {
          width: 100%;
          margin-top: 35px; } }
      #footer .content .block .block-title {
        font-size: 20px;
        line-height: 1.45;
        font-family: PacificoRegular;
        letter-spacing: 0.02em;
        margin-bottom: 25px; }
      @media screen and (max-width: 768px) {
        #footer .content .block .block-content ul {
          display: flex;
          flex-wrap: wrap; } }
      #footer .content .block .block-content ul li {
        margin-bottom: 10px; }
        @media screen and (max-width: 768px) {
          #footer .content .block .block-content ul li {
            margin-right: 25px;
            margin-bottom: 15px !important; } }
        #footer .content .block .block-content ul li a {
          color: #79787f;
          text-decoration: none;
          -webkit-transition: .2s;
          -o-transition: .2s;
          transition: .2s;
          font-size: 15px;
          font-weight: 300; }
          @media screen and (max-width: 768px) {
            #footer .content .block .block-content ul li a {
              font-size: 14px; } }
          #footer .content .block .block-content ul li a:hover {
            color: #1e1d24; }
        #footer .content .block .block-content ul li:not(:last-child) {
          margin-bottom: 10px; }
      #footer .content .block .block-content .subblocks:not(:last-child) {
        margin-bottom: 10px; }
      #footer .content .block .block-content .subblocks i {
        color: #679509;
        font-size: 20px;
        margin-right: 10px; }
      #footer .content .block .block-content .subblocks span {
        color: #79787f;
        text-decoration: none;
        -webkit-transition: .2s;
        -o-transition: .2s;
        transition: .2s;
        font-size: 15px;
        font-weight: 300; }
        @media screen and (max-width: 768px) {
          #footer .content .block .block-content .subblocks span {
            font-size: 14px; } }

.wrapper {
  margin: 0 auto;
  background: #fff;
  overflow: hidden;
  position: relative; }
  @media screen and (max-width: 768px) {
    .wrapper {
      margin-top: 85px; } }

@media screen and (max-width: 768px) {
  #header {
    display: none; } }

#header .search-block {
  position: absolute;
  right: 0;
  top: 0;
  /*transform: translateX(-50%);*/
  z-index: 999999999999999;
  display: none; }
  #header .search-block .search-close {
    position: absolute;
    top: 0;
    left: 25px;
    background: transparent;
    /* width: 100px; */
    color: #fff; }
    @media screen and (min-width: 769px) and (max-width: 991px) {
      #header .search-block .search-close {
        left: 0; } }
  #header .search-block form {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 50px;
    max-width: 800px; }
    @media screen and (min-width: 769px) and (max-width: 991px) {
      #header .search-block form {
        padding-right: 25px;
        padding-left: 25px; } }
    @media screen and (max-width: 768px) {
      #header .search-block form {
        padding-right: 15px;
        padding-left: 15px; } }
    #header .search-block form input {
      width: 500px;
      position: relative;
      z-index: 99999999999;
      height: 38px;
      padding: 0 10px;
      transform: translateX(4px); }
      @media screen and (min-width: 769px) and (max-width: 991px) {
        #header .search-block form input {
          width: 315px; } }
    #header .search-block form button.search-btn {
      height: 38px;
      background: #f0f0f0;
      width: 100px;
      color: #679509;
      text-transform: uppercase;
      letter-spacing: 1.3px;
      transition: all .7s;
      border: 1px solid transparent; }
      #header .search-block form button.search-btn:hover {
        background: #679509;
        color: #fff;
        border: 1px solid #fff; }

#header .header-top {
  background: #679509;
  color: #fff; }
  #header .header-top .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 50px;
    position: relative; }
    @media screen and (min-width: 769px) and (max-width: 991px) {
      #header .header-top .content {
        padding-right: 25px;
        padding-left: 25px; } }
    @media screen and (max-width: 768px) {
      #header .header-top .content {
        padding-right: 15px;
        padding-left: 15px; } }
    #header .header-top .content .left {
      padding: 9px 0; }
      #header .header-top .content .left span {
        margin-right: 10px; }
      #header .header-top .content .left strong {
        letter-spacing: 1.1px; }
    #header .header-top .content .right {
      padding: 9px 0;
      position: relative; }
      #header .header-top .content .right ul li {
        display: inline-block;
        position: relative; }
        #header .header-top .content .right ul li .dropdown-menu {
          margin-top: 11px;
          padding-top: 0;
          border: 1px solid #ccc;
          border-radius: 0;
          min-width: 140px; }
          #header .header-top .content .right ul li .dropdown-menu .divider {
            margin: 0; }
          #header .header-top .content .right ul li .dropdown-menu li {
            width: 100%; }
            #header .header-top .content .right ul li .dropdown-menu li.dropdown-active a {
              background: #679509;
              color: #fff; }
            #header .header-top .content .right ul li .dropdown-menu li:after {
              content: none; }
            #header .header-top .content .right ul li .dropdown-menu li a {
              background: #fff;
              color: #679509;
              padding-top: 7px;
              padding-bottom: 7px; }
              #header .header-top .content .right ul li .dropdown-menu li a:hover {
                background: #f0f0f0; }
        #header .header-top .content .right ul li a {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #f0f0f0;
          text-transform: uppercase;
          padding-right: 17px;
          padding-left: 17px;
          font-size: 12px;
          transition: all .7s; }
          @media screen and (min-width: 769px) and (max-width: 991px) {
            #header .header-top .content .right ul li a {
              padding-right: 8px;
              padding-left: 10px; } }
          #header .header-top .content .right ul li a:hover {
            text-decoration: none;
            color: #c7ed79; }
          #header .header-top .content .right ul li a i {
            font-size: 18px;
            margin-right: 8px; }
            #header .header-top .content .right ul li a i.shopping-cart-active {
              color: #c7ed79 !important; }
            @media screen and (min-width: 769px) and (max-width: 991px) {
              #header .header-top .content .right ul li a i {
                font-size: 16px;
                margin-right: 5px; } }
          #header .header-top .content .right ul li a .cart-items-count-wrap {
            text-transform: lowercase;
            font-weight: bold;
            color: #c7ed79; }
            #header .header-top .content .right ul li a .cart-items-count-wrap .cart-items-count-number {
              margin: 0 1px; }
        #header .header-top .content .right ul li:last-child a {
          padding-right: 0; }
        #header .header-top .content .right ul li:after {
          content: "";
          width: 1.5px;
          height: 22px;
          display: inline-block;
          background: #fff;
          position: absolute;
          top: 0; }
          @media screen and (min-width: 769px) and (max-width: 991px) {
            #header .header-top .content .right ul li:after {
              height: 18px; } }

#header .header-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 50px; }
  @media screen and (min-width: 769px) and (max-width: 991px) {
    #header .header-bottom {
      padding-right: 25px;
      padding-left: 25px; } }
  @media screen and (max-width: 768px) {
    #header .header-bottom {
      padding-right: 15px;
      padding-left: 15px; } }
  #header .header-bottom .left {
    padding: 15px 0; }
    #header .header-bottom .left a img.logo {
      width: 150px; }
  #header .header-bottom .right ul li {
    display: inline-block;
    margin-left: 15px;
    margin-right: 15px;
    position: relative; }
    @media screen and (min-width: 769px) and (max-width: 991px) {
      #header .header-bottom .right ul li {
        margin-left: 10px;
        margin-right: 10px; } }
    #header .header-bottom .right ul li:last-child {
      margin-right: 0; }
    #header .header-bottom .right ul li a {
      display: inline-block;
      color: #a5a5a5;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 14px;
      line-height: 1.643;
      letter-spacing: 0.04em;
      text-decoration: none;
      transition: all .7s; }
      #header .header-bottom .right ul li a:hover {
        color: #86ae34; }
      #header .header-bottom .right ul li a:before {
        content: '\e82d';
        display: inline-block;
        font-family: 'Linearicon';
        line-height: 1;
        speak: none;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        line-height: 1.5;
        position: absolute;
        top: -16px;
        right: -20px;
        width: 100%;
        opacity: 0;
        color: #86ae34;
        -webkit-transform: rotate(0deg) scaleX(1) scaleY(1);
        -ms-transform: rotate(0deg) scaleX(1) scaleY(1);
        transform: rotate(0deg) scaleX(1) scaleY(1); }
      #header .header-bottom .right ul li a:after {
        content: '\e82d';
        display: block !important;
        line-height: 1.5;
        display: inline-block;
        font-family: 'Linearicon';
        line-height: 1;
        speak: none;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        right: calc(100% - 20px);
        top: -7px;
        font-size: 10px;
        width: 100%;
        opacity: 0;
        -webkit-transform: translate(0px, 0px) scaleX(-0.9) scaleY(0.9) skewX(-5deg) skewY(5deg);
        -ms-transform: translate(0px, 0px) scaleX(-0.9) scaleY(0.9) skewX(-5deg) skewY(5deg);
        transform: translate(0px, 0px) scaleX(-0.9) scaleY(0.9) skewX(-5deg) skewY(5deg); }
      #header .header-bottom .right ul li a.active {
        color: #86ae34; }
        #header .header-bottom .right ul li a.active:before {
          opacity: 1;
          color: #86ae34; }
        #header .header-bottom .right ul li a.active:after {
          opacity: 1;
          color: #86ae34; }
      #header .header-bottom .right ul li a:hover {
        color: #86ae34; }
        #header .header-bottom .right ul li a:hover:before {
          opacity: 1;
          color: #86ae34; }
        #header .header-bottom .right ul li a:hover:after {
          opacity: 1;
          color: #86ae34; }
    #header .header-bottom .right ul li ul.dropdown-menu {
      max-width: 300px; }
      #header .header-bottom .right ul li ul.dropdown-menu li {
        width: 100%;
        margin: 0; }
        #header .header-bottom .right ul li ul.dropdown-menu li a {
          width: 100%;
          padding: 3px 20px 3px 40px; }
          #header .header-bottom .right ul li ul.dropdown-menu li a:after {
            right: calc(100% - 24.5px);
            top: 21.5px; }
          #header .header-bottom .right ul li ul.dropdown-menu li a:before {
            top: 4px;
            right: -14px; }

#header-mobile {
  display: none;
  width: 100%;
  background: #679509;
  padding-top: 5px;
  padding-bottom: 5px;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 50px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }
  @media screen and (max-width: 768px) {
    #header-mobile {
      display: block; } }
  @media screen and (min-width: 769px) and (max-width: 991px) {
    #header-mobile {
      padding-right: 25px;
      padding-left: 25px; } }
  @media screen and (max-width: 768px) {
    #header-mobile {
      padding-right: 15px;
      padding-left: 15px; } }
  #header-mobile .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    transition: all .7s; }
    #header-mobile .content .item a {
      display: inline-block; }
      #header-mobile .content .item a img.logo {
        height: 40px; }
      #header-mobile .content .item a#header-mobile-logo {
        background: #fff;
        padding: 0 15px;
        height: 50px; }
    #header-mobile .content .item i#mobile-header-open {
      cursor: pointer;
      font-size: 25px;
      color: #fff; }
    #header-mobile .content .item i#mobile-header-cart {
      color: #fff;
      font-size: 40px;
      line-height: 30px; }

#header-mobile-panel {
  display: none;
  position: fixed;
  top: 40px;
  z-index: 9999;
  height: 100vh;
  background: #679509;
  width: 300px;
  overflow: auto;
  padding-bottom: 50px; }
  #header-mobile-panel .content {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 50px;
    padding-top: 25px;
    padding-bottom: 25px; }
    @media screen and (min-width: 769px) and (max-width: 991px) {
      #header-mobile-panel .content {
        padding-right: 25px;
        padding-left: 25px; } }
    @media screen and (max-width: 768px) {
      #header-mobile-panel .content {
        padding-right: 15px;
        padding-left: 15px; } }
    #header-mobile-panel .content .settings {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      #header-mobile-panel .content .settings span {
        display: inline-block;
        width: 100%;
        margin-bottom: 5px;
        color: #fff;
        font-family: PTSansRegular; }
      #header-mobile-panel .content .settings select {
        padding: 3px; }
    #header-mobile-panel .content .search-mobile {
      margin-bottom: 25px; }
      #header-mobile-panel .content .search-mobile form input {
        height: 30px;
        padding: 5px;
        font-size: 14px; }
      #header-mobile-panel .content .search-mobile form button {
        height: 30px;
        font-size: 14px;
        background: #86ae34;
        color: #fff;
        border: 1px solid #fff;
        margin-left: 7px; }
    #header-mobile-panel .content .cats-mobile {
      margin-bottom: 25px; }
      #header-mobile-panel .content .cats-mobile .cats-mobile-title {
        font-family: PacificoRegular;
        color: #fff;
        font-size: 18px;
        margin-bottom: 10px; }
      #header-mobile-panel .content .cats-mobile ul {
        list-style: circle;
        padding-left: 16px;
        color: #fff; }
        #header-mobile-panel .content .cats-mobile ul li {
          margin-bottom: 5px; }
          #header-mobile-panel .content .cats-mobile ul li a {
            color: #fff; }
    #header-mobile-panel .content .pages-mobile {
      margin-bottom: 25px; }
      #header-mobile-panel .content .pages-mobile .pages-mobile-title {
        font-family: PacificoRegular;
        color: #fff;
        font-size: 18px;
        margin-bottom: 10px; }
      #header-mobile-panel .content .pages-mobile ul {
        display: flex;
        flex-wrap: wrap; }
        #header-mobile-panel .content .pages-mobile ul li {
          margin: 0 15px 10px 0; }
          #header-mobile-panel .content .pages-mobile ul li a {
            color: #fff; }

/*======= main page ========*/
#carousel-wrap .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 50px; }
  @media screen and (min-width: 769px) and (max-width: 991px) {
    #carousel-wrap .content {
      padding-right: 25px;
      padding-left: 25px; } }
  @media screen and (max-width: 768px) {
    #carousel-wrap .content {
      padding-right: 15px;
      padding-left: 15px; } }
  #carousel-wrap .content .left {
    width: 270px;
    padding: 25px 10px 0 0;
    position: relative; }
    @media screen and (min-width: 769px) and (max-width: 991px) {
      #carousel-wrap .content .left {
        display: none; } }
    @media screen and (max-width: 768px) {
      #carousel-wrap .content .left {
        display: none; } }
    #carousel-wrap .content .left h2 {
      font-family: PacificoRegular;
      margin-bottom: 25px; }
    #carousel-wrap .content .left ul#menu-cats {
      /*
				&:before{
					content: "";
				    position: absolute;
				    right: 5px;
				    width: 1.2px;
				    top: 20px;
				    height: 100%;
				    background-color: #e3e3e3;
				    z-index: 9;
				}*/ }
      #carousel-wrap .content .left ul#menu-cats li {
        display: inline-block;
        width: 100%;
        border: 1px solid #ccc;
        border-bottom: none; }
        #carousel-wrap .content .left ul#menu-cats li:last-child {
          border-bottom: 1px solid #ccc; }
        #carousel-wrap .content .left ul#menu-cats li a {
          padding: 5px 10px;
          display: inline-block;
          width: 100%;
          color: #79787f;
          text-decoration: none;
          font-size: 16px; }
          #carousel-wrap .content .left ul#menu-cats li a:hover {
            color: #fff;
            background: #679509; }
  #carousel-wrap .content .right {
    width: calc( 100% - 270px); }
    @media screen and (min-width: 769px) and (max-width: 991px) {
      #carousel-wrap .content .right {
        margin-top: 25px;
        width: 100%; } }
    @media screen and (max-width: 768px) {
      #carousel-wrap .content .right {
        margin-top: 25px;
        width: 100%; } }
    #carousel-wrap .content .right #carousel {
      height: 250px; }
      @media screen and (max-width: 768px) {
        #carousel-wrap .content .right #carousel {
          height: auto; } }
      #carousel-wrap .content .right #carousel .carousel-inner .item img {
        width: auto;
        height: 250px;
        float: right; }
        @media screen and (max-width: 768px) {
          #carousel-wrap .content .right #carousel .carousel-inner .item img {
            width: 100%;
            height: auto;
            max-width: 600px; } }
        @media screen and (min-width: 769px) and (max-width: 991px) {
          #carousel-wrap .content .right #carousel .carousel-inner .item img {
            float: none;
            margin: 0 auto; } }
        @media screen and (max-width: 768px) {
          #carousel-wrap .content .right #carousel .carousel-inner .item img {
            float: none;
            margin: 0 auto; } }
      #carousel-wrap .content .right #carousel .carousel-indicators {
        bottom: -50px; }
        #carousel-wrap .content .right #carousel .carousel-indicators li {
          background: #679509;
          border: 1px solid #679509;
          border-radius: 0;
          opacity: 0.7; }
          #carousel-wrap .content .right #carousel .carousel-indicators li.active {
            opacity: 1; }

#random-products {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 50px;
  margin-top: 100px; }
  @media screen and (min-width: 769px) and (max-width: 991px) {
    #random-products {
      padding-right: 25px;
      padding-left: 25px; } }
  @media screen and (max-width: 768px) {
    #random-products {
      padding-right: 15px;
      padding-left: 15px; } }
  #random-products h2 {
    text-align: center;
    margin-bottom: 50px;
    color: #505050;
    font-family: PacificoRegular;
    font-size: 32px; }
    @media screen and (max-width: 500px) {
      #random-products h2 {
        display: none; } }
  #random-products .content {
    cursor: grab;
    padding-bottom: 0; }
    #random-products .content .item {
      text-align: center;
      width: 30%;
      display: flex;
      margin-bottom: 50px;
      border: 1px solid #ccc;
      padding: 10px 10px 25px 10px; }
      #random-products .content .item .item-content {
        margin: 0 auto; }
        #random-products .content .item .item-content .item-img a img {
          max-width: 320px;
          width: 100%; }
          @media screen and (max-width: 500px) {
            #random-products .content .item .item-content .item-img a img {
              max-width: 250px; } }
        #random-products .content .item .item-content .item-name {
          font-weight: 400;
          font-size: 15px;
          line-height: 1.44;
          text-align: inherit;
          color: #333333;
          margin-bottom: 15px;
          margin-top: 15px;
          min-height: 45px; }
          #random-products .content .item .item-content .item-name a {
            color: #333333; }
          @media screen and (max-width: 768px) {
            #random-products .content .item .item-content .item-name {
              font-size: 12px; } }
        #random-products .content .item .item-content .item-price {
          color: #86ae34;
          font-style: normal;
          font-weight: 900;
          font-size: 20px;
          line-height: 1.3;
          margin-bottom: 15px; }
          @media screen and (max-width: 768px) {
            #random-products .content .item .item-content .item-price {
              font-size: 14px; } }
          #random-products .content .item .item-content .item-price .item-price-count {
            font-size: 24px;
            color: #86ae34;
            margin: 0 2px 0 10px;
            font-weight: bold; }
            @media screen and (max-width: 768px) {
              #random-products .content .item .item-content .item-price .item-price-count {
                font-size: 18px;
                margin: 0 0px 0 3px; } }
          #random-products .content .item .item-content .item-price .item-price-currency {
            color: #86ae34; }
        #random-products .content .item .item-content .item-cart {
          text-align: center; }
          @media screen and (min-width: 769px) and (max-width: 991px) {
            #random-products .content .item .item-content .item-cart button {
              width: 180px; } }
          @media screen and (max-width: 768px) {
            #random-products .content .item .item-content .item-cart button {
              width: 140px;
              font-size: 12px;
              height: 30px; } }
  #random-products .swiper-pagination {
    bottom: 0; }
    #random-products .swiper-pagination .swiper-pagination-bullet {
      background: #79787f; }
    #random-products .swiper-pagination .swiper-pagination-bullet-active {
      background: #86ae34; }

#why-we {
  background: url(images/bg-gray.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  margin: 75px 0 0px 0;
  padding: 100px 0 100px 0; }
  @media screen and (max-width: 768px) {
    #why-we {
      padding: 50px 0 25px 0;
      margin: 100px 0 50px 0;
      background: #F1F1F1; } }
  @media screen and (max-width: 500px) {
    #why-we {
      margin: 50px 0 50px 0; } }
  #why-we h2 {
    color: #86ae34;
    font-family: PacificoRegular;
    text-align: center;
    word-spacing: 5px; }
    @media screen and (max-width: 768px) {
      #why-we h2 {
        font-size: 24px; } }
  #why-we .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 50px;
    margin-top: 50px;
    align-items: end;
    flex-wrap: wrap; }
    @media screen and (min-width: 769px) and (max-width: 991px) {
      #why-we .content {
        padding-right: 25px;
        padding-left: 25px; } }
    @media screen and (max-width: 768px) {
      #why-we .content {
        padding-right: 15px;
        padding-left: 15px; } }
    @media screen and (max-width: 500px) {
      #why-we .content {
        margin-top: 35px; } }
    #why-we .content .items {
      padding: 15px;
      width: 30%; }
      @media screen and (max-width: 500px) {
        #why-we .content .items {
          width: 100%;
          margin-bottom: 25px;
          padding: 0; } }
      #why-we .content .items .top {
        text-align: center;
        margin-bottom: 35px; }
        @media screen and (max-width: 500px) {
          #why-we .content .items .top {
            margin-bottom: 15px; } }
        #why-we .content .items .top img {
          margin: 0 auto; }
          @media screen and (max-width: 768px) {
            #why-we .content .items .top img {
              height: 40px; } }
      #why-we .content .items .center {
        text-align: center;
        font-weight: 400;
        font-size: 20px;
        line-height: 1.45;
        font-family: PacificoRegular;
        letter-spacing: 0.02em;
        color: #4f4f4f;
        margin-bottom: 25px; }
        @media screen and (max-width: 768px) {
          #why-we .content .items .center {
            font-size: 18px; } }
        @media screen and (max-width: 500px) {
          #why-we .content .items .center {
            margin-bottom: 15px; } }
      #why-we .content .items .bottom {
        color: #8b8b8b;
        text-align: justify; }

#our-products {
  margin: 75px 0 50px 0; }
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    #our-products {
      margin: 50px 0 50px 0; } }
  @media screen and (min-width: 769px) and (max-width: 991px) {
    #our-products {
      margin: 25px 0 25px 0; } }
  @media screen and (max-width: 768px) {
    #our-products {
      margin: 25px 0 25px 0; } }
  #our-products h2 {
    font-family: PacificoRegular;
    text-align: center;
    margin-bottom: 35px; }
    @media screen and (max-width: 768px) {
      #our-products h2 {
        font-size: 24px; } }
  #our-products .products-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 50px;
    display: flex;
    flex-wrap: wrap; }
    @media screen and (min-width: 769px) and (max-width: 991px) {
      #our-products .products-container {
        padding-right: 25px;
        padding-left: 25px; } }
    @media screen and (max-width: 768px) {
      #our-products .products-container {
        padding-right: 15px;
        padding-left: 15px; } }
    #our-products .products-container .item-left {
      display: flex;
      width: 300px;
      padding-right: 0px; }
      @media screen and (min-width: 992px) and (max-width: 1200px) {
        #our-products .products-container .item-left {
          width: 250px; } }
      @media screen and (min-width: 769px) and (max-width: 991px) {
        #our-products .products-container .item-left {
          width: 200px; } }
      @media screen and (max-width: 768px) {
        #our-products .products-container .item-left {
          width: 150px; } }
      @media screen and (max-width: 500px) {
        #our-products .products-container .item-left {
          width: 100%;
          max-width: 350px;
          margin: 0 auto; } }
      #our-products .products-container .item-left .left {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-right: 10px; }
        @media screen and (max-width: 768px) {
          #our-products .products-container .item-left .left {
            padding-right: 5px; } }
        @media screen and (max-width: 500px) {
          #our-products .products-container .item-left .left {
            padding-right: 0px; } }
        #our-products .products-container .item-left .left .cat-block a {
          width: 100%; }
          #our-products .products-container .item-left .left .cat-block a img {
            width: 100%;
            max-width: 100%;
            transition: all .7s ease-in-out;
            -webkit-filter: grayscale(66%);
            filter: grayscale(66%); }
            @media screen and (max-width: 500px) {
              #our-products .products-container .item-left .left .cat-block a img {
                width: 100%; } }
            #our-products .products-container .item-left .left .cat-block a img:hover {
              -webkit-filter: grayscale(0%);
              filter: grayscale(0%);
              transform: translateY(-5px);
              -webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
              -moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
              box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75); }
        #our-products .products-container .item-left .left .cat-block:not(:last-child) {
          margin-bottom: 10px; }
          @media screen and (max-width: 768px) {
            #our-products .products-container .item-left .left .cat-block:not(:last-child) {
              margin-bottom: 5px; } }
        @media screen and (max-width: 500px) {
          #our-products .products-container .item-left .left .cat-block {
            margin-bottom: 25px !important; } }
    #our-products .products-container .item-right {
      display: flex;
      width: calc(100% - 300px); }
      @media screen and (min-width: 992px) and (max-width: 1200px) {
        #our-products .products-container .item-right {
          width: calc(100% - 250px); } }
      @media screen and (min-width: 769px) and (max-width: 991px) {
        #our-products .products-container .item-right {
          width: calc(100% - 200px); } }
      @media screen and (max-width: 768px) {
        #our-products .products-container .item-right {
          width: calc(100% - 150px); } }
      @media screen and (max-width: 500px) {
        #our-products .products-container .item-right {
          width: 100%;
          max-width: 350px;
          margin: 0 auto; } }
      #our-products .products-container .item-right .right {
        display: flex;
        flex-direction: column;
        position: relative; }
        #our-products .products-container .item-right .right .content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap; }
          #our-products .products-container .item-right .right .content .cat-block {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px; }
            @media screen and (max-width: 768px) {
              #our-products .products-container .item-right .right .content .cat-block {
                margin-bottom: 5px; } }
            @media screen and (max-width: 500px) {
              #our-products .products-container .item-right .right .content .cat-block {
                flex-wrap: wrap;
                margin-bottom: 25px; } }
            #our-products .products-container .item-right .right .content .cat-block a {
              width: 100%; }
              #our-products .products-container .item-right .right .content .cat-block a img {
                width: 100%;
                transition: all .7s ease-in-out;
                -webkit-filter: grayscale(66%);
                filter: grayscale(66%); }
                #our-products .products-container .item-right .right .content .cat-block a img:hover {
                  -webkit-filter: grayscale(0%);
                  filter: grayscale(0%);
                  transform: translateY(-5px);
                  -webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
                  -moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
                  box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75); }
            #our-products .products-container .item-right .right .content .cat-block .cat-subblock {
              width: calc(50% - 5px); }
              @media screen and (max-width: 768px) {
                #our-products .products-container .item-right .right .content .cat-block .cat-subblock {
                  width: calc(50% - 2.5px); } }
              @media screen and (max-width: 500px) {
                #our-products .products-container .item-right .right .content .cat-block .cat-subblock {
                  width: 100%; } }
              @media screen and (max-width: 500px) {
                #our-products .products-container .item-right .right .content .cat-block .cat-subblock:not(:last-child) {
                  margin-bottom: 25px; } }
              #our-products .products-container .item-right .right .content .cat-block .cat-subblock a img {
                width: 100%;
                transition: all .7s ease-in-out;
                -webkit-filter: grayscale(66%);
                filter: grayscale(66%); }
                #our-products .products-container .item-right .right .content .cat-block .cat-subblock a img:hover {
                  -webkit-filter: grayscale(0%);
                  filter: grayscale(0%);
                  transform: translateY(-5px);
                  -webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
                  -moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
                  box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75); }
            #our-products .products-container .item-right .right .content .cat-block.center {
              position: relative; }
            #our-products .products-container .item-right .right .content .cat-block.bottom {
              position: relative; }

#blog-on-main {
  background: url("images/bg-gray.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  margin: 25px 0 0px 0;
  padding: 100px 0 150px 0; }
  @media screen and (max-width: 768px) {
    #blog-on-main {
      padding: 50px 0 25px 0;
      margin: 75px 0 50px 0;
      background: #F1F1F1; } }
  #blog-on-main .content {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 50px; }
    @media screen and (min-width: 769px) and (max-width: 991px) {
      #blog-on-main .content {
        padding-right: 25px;
        padding-left: 25px; } }
    @media screen and (max-width: 768px) {
      #blog-on-main .content {
        padding-right: 15px;
        padding-left: 15px; } }
    #blog-on-main .content h2 {
      text-align: center;
      margin-bottom: 50px;
      color: #505050;
      font-family: PacificoRegular;
      font-size: 32px; }
      @media screen and (max-width: 768px) {
        #blog-on-main .content h2 {
          font-size: 24px; } }
    #blog-on-main .content .blog-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-items: end;
      flex-wrap: wrap; }
      #blog-on-main .content .blog-wrap .blog-container {
        width: 30%; }
        @media screen and (max-width: 500px) {
          #blog-on-main .content .blog-wrap .blog-container {
            width: 100%;
            max-width: 350px;
            margin: 0 auto;
            margin-bottom: 50px; } }
        #blog-on-main .content .blog-wrap .blog-container .blog-img {
          margin-bottom: 10px; }
          #blog-on-main .content .blog-wrap .blog-container .blog-img img {
            max-width: 100%; }
        #blog-on-main .content .blog-wrap .blog-container .blog-date {
          margin-bottom: 15px; }
          #blog-on-main .content .blog-wrap .blog-container .blog-date span {
            color: #79787f;
            font-weight: bold; }
        #blog-on-main .content .blog-wrap .blog-container .blog-title {
          margin-bottom: 10px; }
          #blog-on-main .content .blog-wrap .blog-container .blog-title a {
            font-size: 18px;
            font-weight: 600;
            color: #4f4f4f; }
            @media screen and (max-width: 768px) {
              #blog-on-main .content .blog-wrap .blog-container .blog-title a {
                font-size: 16px; } }
            #blog-on-main .content .blog-wrap .blog-container .blog-title a:hover {
              color: #86ae34; }
        #blog-on-main .content .blog-wrap .blog-container .blog-text {
          color: #8b8b8b;
          margin-bottom: 15px; }
        #blog-on-main .content .blog-wrap .blog-container .blog-more button {
          background-color: #86ae34;
          color: #fff;
          padding: 2px 9px;
          font-weight: 400;
          font-size: 12px;
          line-height: 1.75;
          transition: all .7s; }
          #blog-on-main .content .blog-wrap .blog-container .blog-more button:hover {
            background-color: #1e1d24; }

#subscribe {
  padding: 50px 0;
  position: relative; }
  @media screen and (min-width: 769px) and (max-width: 991px) {
    #subscribe {
      padding: 25px 0; } }
  @media screen and (max-width: 768px) {
    #subscribe {
      padding: 35px 0 0 0; } }
  #subscribe .content {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 50px; }
    @media screen and (min-width: 769px) and (max-width: 991px) {
      #subscribe .content {
        padding-right: 25px;
        padding-left: 25px; } }
    @media screen and (max-width: 768px) {
      #subscribe .content {
        padding-right: 15px;
        padding-left: 15px; } }
    #subscribe .content h3 {
      text-align: center;
      margin-bottom: 50px;
      color: #505050;
      font-family: PacificoRegular;
      font-size: 32px; }
      @media screen and (max-width: 768px) {
        #subscribe .content h3 {
          font-size: 24px;
          margin-bottom: 25px; } }
    #subscribe .content .form-block {
      text-align: center; }
      #subscribe .content .form-block form {
        margin: 0 auto;
        display: flex;
        justify-content: center; }
        #subscribe .content .form-block form input {
          width: 400px;
          border-color: #e3e3e3;
          color: #79787f;
          box-shadow: inset 0 0 0 1px #fafafa;
          padding-top: 14px;
          padding-bottom: 14px;
          padding: 9.5px 20px;
          font-size: 18px; }
          @media screen and (max-width: 768px) {
            #subscribe .content .form-block form input {
              max-width: 250px;
              width: calc(100% - 60px);
              padding: 9.5px 10px;
              font-size: 14px; } }
          #subscribe .content .form-block form input:focus {
            border-color: #e3e3e3;
            box-shadow: inset 0 0 0 1px #e3e3e3; }
        #subscribe .content .form-block form button {
          width: 140px;
          background-color: #86ae34;
          color: #fff;
          font-weight: 400;
          font-size: 16px;
          font-family: PTSansRegular, sans-serif;
          border: none;
          outline: none;
          margin: 0 0 0 0px;
          padding: 0 3px;
          text-transform: uppercase; }
          @media screen and (max-width: 768px) {
            #subscribe .content .form-block form button {
              width: 40px;
              margin: 0; } }
          #subscribe .content .form-block form button span {
            display: inline-block; }
            @media screen and (max-width: 769px) {
              #subscribe .content .form-block form button span {
                display: none; } }
          #subscribe .content .form-block form button i {
            display: none; }
            @media screen and (max-width: 769px) {
              #subscribe .content .form-block form button i {
                display: inline-block; } }
        #subscribe .content .form-block form input, #subscribe .content .form-block form button {
          height: 46px; }
          @media screen and (min-width: 769px) and (max-width: 991px) {
            #subscribe .content .form-block form input, #subscribe .content .form-block form button {
              height: 40px; } }
          @media screen and (max-width: 768px) {
            #subscribe .content .form-block form input, #subscribe .content .form-block form button {
              height: 40px; } }

@charset "UTF-8";
/* global params */
@font-face {
  font-family: PacificoRegular;
  /* Имя шрифта */
  src: url(fonts/Pacifico-Regular.ttf);
  /* Путь к файлу со шрифтом */ }

@font-face {
  font-family: PTSansRegular;
  /* Имя шрифта */
  src: url(fonts/PTSans/PTSans-Regular.ttf);
  /* Путь к файлу со шрифтом */ }

@font-face {
  font-family: 'Linearicon';
  /* Имя шрифта */
  src: url(fonts/linearicons.ttf);
  /* Путь к файлу со шрифтом */ }

body {
  font-family: PTSansRegular, sans-serif;
  padding: 0;
  margin: 0;
  background: #fff; }

ul, ol {
  padding: 0;
  margin: 0;
  list-style: none; }

a {
  padding: 0;
  margin: 0;
  text-decoration: none; }

a:hover, a:focus, a:active, a:visited {
  text-decoration: none; }

p, h1, h2, h3, h4, h5, h6 {
  padding: 0;
  margin: 0; }

.no-scroll {
  overflow: hidden; }

h1 {
  font-weight: 400;
  font-size: 32px;
  line-height: 1.94;
  color: #4f4f4f;
  font-family: PacificoRegular;
  text-align: center; }
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    h1 {
      font-size: 28px; } }
  @media screen and (min-width: 769px) and (max-width: 991px) {
    h1 {
      font-size: 24px; } }
  @media screen and (max-width: 769px) {
    h1 {
      font-size: 24px; } }

.form-error {
  width: 100% !important;
  background: #f0f0f0 !important;
  padding: 25px !important;
  display: none; }

.form-success {
  display: none;
  background-color: #f0f0f0;
  padding: 25px;
  text-align: center; }
  .form-success p {
    margin-bottom: 25px; }
  .form-success div {
    margin-top: 25px; }

.phone-checker-red {
  border-color: #EA7C60 !important; }

.product-desc-full {
  max-height: none !important; }

.my-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 50px; }
  @media screen and (min-width: 769px) and (max-width: 991px) {
    .my-container {
      padding-right: 25px;
      padding-left: 25px; } }
  @media screen and (max-width: 768px) {
    .my-container {
      padding-right: 15px;
      padding-left: 15px; } }

button {
  outline: none;
  border: none; }
  button.btn-gray {
    height: 35px;
    padding: 0 15px;
    background: #f0f0f0;
    transition: all .7s; }
    button.btn-gray:hover {
      background: #101010;
      color: #fff; }

input {
  outline: none;
  border: 1px solid #e8e8e8; }

.page-grid {
  margin: 25px 0 75px 0; }
  @media screen and (max-width: 768px) {
    .page-grid {
      margin: 25px 0 50px 0; } }

.no-products {
  margin: 75px auto 50px auto; }
  @media screen and (max-width: 768px) {
    .no-products {
      margin: 50px auto 25px auto; } }
  .no-products p {
    text-align: center;
    font-size: 24px; }
    @media screen and (max-width: 768px) {
      .no-products p {
        font-size: 18px; } }

.text-gray {
  color: #a5a5a5;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7;
  font-family: PTSansRegular, sans-serif;
  letter-spacing: 0em; }
  @media screen and (max-width: 768px) {
    .text-gray {
      font-size: 14px; } }

::-webkit-input-placeholder {
  font-size: 18px;
  color: #b5b5b5;
  font-family: PTSansRegular, sans-serif; }
  @media screen and (max-width: 768px) {
    ::-webkit-input-placeholder {
      font-size: 14px; } }

::-moz-placeholder {
  font-size: 18px;
  color: #b5b5b5;
  font-family: PTSansRegular, sans-serif; }
  @media screen and (max-width: 768px) {
    ::-moz-placeholder {
      font-size: 14px; } }

/* Firefox 19+ */
:-moz-placeholder {
  font-size: 18px;
  color: #b5b5b5;
  font-family: PTSansRegular, sans-serif; }
  @media screen and (max-width: 768px) {
    :-moz-placeholder {
      font-size: 14px; } }

/* Firefox 18- */
:-ms-input-placeholder {
  font-size: 18px;
  color: #b5b5b5;
  font-family: PTSansRegular, sans-serif; }
  @media screen and (max-width: 768px) {
    :-ms-input-placeholder {
      font-size: 14px; } }

/* global params */
.zoom {
  cursor: move; }

.dropdown:hover > .dropdown-menu {
  display: block; }

.swiper-pagination .swiper-pagination-bullet {
  background: #79787f; }

.swiper-pagination .swiper-pagination-bullet-active {
  background: #86ae34; }

/* add-to-cart */
.window-bottom-add-to-cart {
  background: #414141;
  color: #fff;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 350px;
  z-index: 99999;
  padding: 15px 25px 10px 25px;
  display: none; }
  @media screen and (max-width: 768px) {
    .window-bottom-add-to-cart {
      width: 300px;
      padding: 15px 10px 10px 10px; } }
  .window-bottom-add-to-cart .close-bottom-add-to-cart {
    position: absolute;
    top: -2px;
    left: -26px;
    color: #414141;
    cursor: pointer;
    font-size: 18px; }
  .window-bottom-add-to-cart .content {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .window-bottom-add-to-cart .content .left a i {
      font-size: 45px;
      color: #fff; }
    .window-bottom-add-to-cart .content .left a:hover i {
      color: #86ae34; }

/* .carousel.fading */
.carousel.fade {
  opacity: 1; }

.carousel.fade .item {
  -moz-transition: opacity ease-in-out .7s;
  -o-transition: opacity ease-in-out .7s;
  -webkit-transition: opacity ease-in-out .7s;
  transition: opacity ease-in-out .7s;
  left: 0 !important;
  opacity: 0;
  top: 0;
  position: absolute;
  width: 100%;
  display: block !important;
  z-index: 1; }

.carousel.fade .item:first-child {
  top: auto;
  position: relative; }

.carousel.fade .item.active {
  opacity: 1;
  -moz-transition: opacity ease-in-out .7s;
  -o-transition: opacity ease-in-out .7s;
  -webkit-transition: opacity ease-in-out .7s;
  transition: opacity ease-in-out .7s;
  z-index: 2; }

input.inpt-default {
  padding: 10px 20px;
  background-color: #fff;
  border-color: #c9cdcf;
  border-radius: 3px;
  color: #9ba0a3;
  width: 100%;
  margin-bottom: 25px;
  font-size: 18px; }
  @media screen and (max-width: 768px) {
    input.inpt-default {
      font-size: 14px;
      padding: 9px 10px;
      margin-bottom: 15px; } }

input.inpt-default:focus {
  box-shadow: inset 0 0 0 1px #e3e3e3; }

button.btn-animation {
  width: 205px;
  background-color: #86ae34;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  font-family: PTSansRegular, sans-serif;
  border: none;
  outline: none;
  height: 40px;
  text-transform: uppercase;
  transform: translateY(0); }
  @media screen and (min-width: 769px) and (max-width: 991px) {
    button.btn-animation {
      font-size: 14px;
      height: 35px; } }
  @media screen and (max-width: 768px) {
    button.btn-animation {
      font-size: 14px;
      height: 35px; } }
  button.btn-animation:after {
    content: '';
    position: absolute;
    width: 0%;
    height: 100%;
    top: 0;
    left: 0;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    background-clip: content-box;
    z-index: -1;
    pointer-events: none;
    background-color: #1e1d24; }
  button.btn-animation:hover {
    opacity: 1; }
    button.btn-animation:hover:after {
      width: 100%; }
  button.btn-animation:active(:after) {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0; }

.page-preloader-cover {
  position: fixed;
  z-index: 1200;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff; }

.page-preloader-cover svg {
  display: block;
  width: 258px;
  height: 258px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.tree {
  fill: #679509; }

.circle-mask {
  -webkit-transform-origin: 50% 90%;
  -ms-transform-origin: 50% 90%;
  transform-origin: 50% 90%;
  -webkit-animation: scale 5s infinite ease-out;
  animation: scale 5s infinite ease-out; }

@-webkit-keyframes scale {
  0%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  7%, 90% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4); }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes scale {
  0%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  7%, 90% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4); }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

#toTop {
  display: none;
  text-decoration: none;
  position: fixed;
  bottom: 20px;
  right: -999em;
  overflow: hidden;
  width: 44px;
  height: 44px;
  z-index: 999;
  text-align: center;
  border-radius: 50%; }

/* .carousel.fading */
.header-small-fixed {
  position: fixed;
  z-index: 999999999999999999999999999;
  background: rgba(103, 149, 9, 0.9) !important;
  width: 100%; }
  .header-small-fixed .content {
    height: 40px !important; }
  .header-small-fixed #header-mobile-logo {
    height: 40px !important; }
    .header-small-fixed #header-mobile-logo .logo {
      height: 35px !important; }

.form-error {
  padding: 15px !important;
  margin-bottom: 15px; }

.form-loading {
  display: none;
  position: absolute;
  top: 0;
  transform: translate(-50%);
  left: 50%;
  width: 100%;
  background: rgba(240, 240, 240, 0.3);
  text-align: center;
  height: 100%;
  margin: auto; }
  .form-loading #floatBarsG {
    position: relative;
    width: 234px;
    top: 50%;
    height: 28px;
    margin: auto;
    transform: translateY(-50%); }

.breadcrumps {
  background-color: #f0f0f0;
  /*margin-top: 15px;*/ }
  .breadcrumps .content {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px; }
    @media screen and (min-width: 769px) and (max-width: 991px) {
      .breadcrumps .content {
        padding-right: 25px;
        padding-left: 25px; } }
    @media screen and (max-width: 768px) {
      .breadcrumps .content {
        padding-right: 15px;
        padding-left: 15px; } }
    @media screen and (max-width: 768px) {
      .breadcrumps .content .left {
        display: none; } }
    .breadcrumps .content .left a {
      color: #a5a5a5; }
    .breadcrumps .content .right {
      text-align: right; }
      @media screen and (max-width: 768px) {
        .breadcrumps .content .right {
          text-align: left; } }
      .breadcrumps .content .right a {
        color: #a5a5a5; }
      .breadcrumps .content .right span.br-active {
        color: #4f4f4f; }
      .breadcrumps .content .right .deliver {
        margin: 0 5px; }

@media screen and (max-width: 768px) {
  .modal {
    top: 50px !important; } }

#myModalLogin, #holidaysGraphic {
  padding-right: 0 !important; }
  #myModalLogin .modal-content .modal-header h4, #holidaysGraphic .modal-content .modal-header h4 {
    font-family: PacificoRegular;
    text-align: center;
    font-size: 28px; }
    @media screen and (max-width: 768px) {
      #myModalLogin .modal-content .modal-header h4, #holidaysGraphic .modal-content .modal-header h4 {
        font-size: 24px; } }
  #myModalLogin .modal-body form#enterForm, #holidaysGraphic .modal-body form#enterForm {
    width: 400px;
    margin: 25px auto; }
    @media screen and (max-width: 768px) {
      #myModalLogin .modal-body form#enterForm, #holidaysGraphic .modal-body form#enterForm {
        width: 250px;
        margin: 10px auto; } }
    #myModalLogin .modal-body form#enterForm .enterField button#enterSubmit, #holidaysGraphic .modal-body form#enterForm .enterField button#enterSubmit {
      width: 100%;
      height: 45px; }
      @media screen and (max-width: 768px) {
        #myModalLogin .modal-body form#enterForm .enterField button#enterSubmit, #holidaysGraphic .modal-body form#enterForm .enterField button#enterSubmit {
          height: 37px; } }
    #myModalLogin .modal-body form#enterForm .form-error, #holidaysGraphic .modal-body form#enterForm .form-error {
      padding: 15px !important;
      margin-bottom: 15px; }
  #myModalLogin .modal-body .form-loading, #holidaysGraphic .modal-body .form-loading {
    display: none;
    position: absolute;
    top: 0;
    transform: translate(-50%);
    left: 50%;
    width: 100%;
    background: rgba(240, 240, 240, 0.3);
    text-align: center;
    height: 100%;
    margin: auto; }
    #myModalLogin .modal-body .form-loading #floatBarsG, #holidaysGraphic .modal-body .form-loading #floatBarsG {
      position: relative;
      width: 234px;
      top: 50%;
      height: 28px;
      margin: auto;
      transform: translateY(-50%); }
  #myModalLogin .modal-body .user-cabinet, #holidaysGraphic .modal-body .user-cabinet {
    margin: 25px auto; }
    #myModalLogin .modal-body .user-cabinet .content, #holidaysGraphic .modal-body .user-cabinet .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-around; }
      @media screen and (max-width: 500px) {
        #myModalLogin .modal-body .user-cabinet .content .left, #holidaysGraphic .modal-body .user-cabinet .content .left {
          width: 100%;
          margin-bottom: 25px;
          padding-bottom: 25px;
          max-width: 280px;
          border-bottom: 1px solid #ccc; } }
      #myModalLogin .modal-body .user-cabinet .content .left div, #holidaysGraphic .modal-body .user-cabinet .content .left div {
        margin-top: 15px; }
      @media screen and (max-width: 500px) {
        #myModalLogin .modal-body .user-cabinet .content .right, #holidaysGraphic .modal-body .user-cabinet .content .right {
          width: 100%;
          max-width: 280px; } }
      #myModalLogin .modal-body .user-cabinet .content .right div, #holidaysGraphic .modal-body .user-cabinet .content .right div {
        margin-top: 15px; }
  #myModalLogin .modal-footer, #holidaysGraphic .modal-footer {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.7;
    font-family: PTSansRegular, sans-serif;
    letter-spacing: 0em;
    text-align: center; }
    #myModalLogin .modal-footer span, #holidaysGraphic .modal-footer span {
      color: #a5a5a5;
      margin-right: 10px; }
    #myModalLogin .modal-footer a, #holidaysGraphic .modal-footer a {
      color: #86ae34; }

/* ==============     css load animations ===================*/
/* ==============     css load animation 1 ===================*/
#floatBarsG {
  position: relative;
  width: 234px;
  height: 28px;
  margin: auto; }

.floatBarsG {
  position: absolute;
  top: 0;
  background-color: #669509;
  width: 28px;
  height: 28px;
  animation-name: bounce_floatBarsG;
  -o-animation-name: bounce_floatBarsG;
  -ms-animation-name: bounce_floatBarsG;
  -webkit-animation-name: bounce_floatBarsG;
  -moz-animation-name: bounce_floatBarsG;
  animation-duration: 1.5s;
  -o-animation-duration: 1.5s;
  -ms-animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  -moz-animation-duration: 1.5s;
  animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-direction: normal;
  -o-animation-direction: normal;
  -ms-animation-direction: normal;
  -webkit-animation-direction: normal;
  -moz-animation-direction: normal;
  transform: scale(0.3);
  -o-transform: scale(0.3);
  -ms-transform: scale(0.3);
  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3); }

#floatBarsG_1 {
  left: 0;
  animation-delay: 0.6s;
  -o-animation-delay: 0.6s;
  -ms-animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s; }

#floatBarsG_2 {
  left: 29px;
  animation-delay: 0.75s;
  -o-animation-delay: 0.75s;
  -ms-animation-delay: 0.75s;
  -webkit-animation-delay: 0.75s;
  -moz-animation-delay: 0.75s; }

#floatBarsG_3 {
  left: 58px;
  animation-delay: 0.9s;
  -o-animation-delay: 0.9s;
  -ms-animation-delay: 0.9s;
  -webkit-animation-delay: 0.9s;
  -moz-animation-delay: 0.9s; }

#floatBarsG_4 {
  left: 88px;
  animation-delay: 1.05s;
  -o-animation-delay: 1.05s;
  -ms-animation-delay: 1.05s;
  -webkit-animation-delay: 1.05s;
  -moz-animation-delay: 1.05s; }

#floatBarsG_5 {
  left: 117px;
  animation-delay: 1.2s;
  -o-animation-delay: 1.2s;
  -ms-animation-delay: 1.2s;
  -webkit-animation-delay: 1.2s;
  -moz-animation-delay: 1.2s; }

#floatBarsG_6 {
  left: 146px;
  animation-delay: 1.35s;
  -o-animation-delay: 1.35s;
  -ms-animation-delay: 1.35s;
  -webkit-animation-delay: 1.35s;
  -moz-animation-delay: 1.35s; }

#floatBarsG_7 {
  left: 175px;
  animation-delay: 1.5s;
  -o-animation-delay: 1.5s;
  -ms-animation-delay: 1.5s;
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s; }

#floatBarsG_8 {
  left: 205px;
  animation-delay: 1.64s;
  -o-animation-delay: 1.64s;
  -ms-animation-delay: 1.64s;
  -webkit-animation-delay: 1.64s;
  -moz-animation-delay: 1.64s; }

@keyframes bounce_floatBarsG {
  0% {
    transform: scale(1);
    background-color: #669509; }
  100% {
    transform: scale(0.3);
    background-color: white; } }

@-o-keyframes bounce_floatBarsG {
  0% {
    -o-transform: scale(1);
    background-color: #669509; }
  100% {
    -o-transform: scale(0.3);
    background-color: white; } }

@-ms-keyframes bounce_floatBarsG {
  0% {
    -ms-transform: scale(1);
    background-color: #669509; }
  100% {
    -ms-transform: scale(0.3);
    background-color: white; } }

@-webkit-keyframes bounce_floatBarsG {
  0% {
    -webkit-transform: scale(1);
    background-color: #669509; }
  100% {
    -webkit-transform: scale(0.3);
    background-color: white; } }

@-moz-keyframes bounce_floatBarsG {
  0% {
    -moz-transform: scale(1);
    background-color: #669509; }
  100% {
    -moz-transform: scale(0.3);
    background-color: white; } }

/* ==============     css load animation 1 ===================*/
/* ==============     css load animations ===================*/

#page .page-grid {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 50px;
  margin: 25px auto 0 auto; }
  @media screen and (min-width: 769px) and (max-width: 991px) {
    #page .page-grid {
      padding-right: 25px;
      padding-left: 25px; } }
  @media screen and (max-width: 768px) {
    #page .page-grid {
      padding-right: 15px;
      padding-left: 15px; } }
  #page .page-grid .page-wrap {
    margin: 50px auto; }
    @media screen and (max-width: 768px) {
      #page .page-grid .page-wrap {
        margin: 25px auto; } }
    #page .page-grid .page-wrap .content {
      background-color: #F2F3F4;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.45);
      -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.45);
      box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.45); }
      #page .page-grid .page-wrap .content .left {
        border-right: 1px solid #ccc;
        width: 50%;
        margin: 0; }
        @media screen and (max-width: 768px) {
          #page .page-grid .page-wrap .content .left {
            width: 100%;
            border-right: none;
            border-bottom: 1px solid #ccc; } }
        #page .page-grid .page-wrap .content .left iframe {
          width: 100%;
          height: 350px; }
      #page .page-grid .page-wrap .content .right {
        width: 45%;
        margin: 0; }
        @media screen and (max-width: 768px) {
          #page .page-grid .page-wrap .content .right {
            width: 100%;
            padding: 50px 25px 25px 25px; } }
        #page .page-grid .page-wrap .content .right .block-content {
          margin: 0; }
          #page .page-grid .page-wrap .content .right .block-content .subblocks:not(:last-child) {
            margin-bottom: 10px; }
          #page .page-grid .page-wrap .content .right .block-content .subblocks i {
            color: #679509;
            font-size: 20px;
            margin-right: 10px; }
            #page .page-grid .page-wrap .content .right .block-content .subblocks i span {
              margin-left: 15px;
              font-family: PTSansRegular, sans-serif; }
          #page .page-grid .page-wrap .content .right .block-content .subblocks span {
            color: #79787f;
            text-decoration: none;
            -webkit-transition: .2s;
            -o-transition: .2s;
            transition: .2s;
            font-size: 15px;
            font-weight: 300; }
            @media screen and (max-width: 768px) {
              #page .page-grid .page-wrap .content .right .block-content .subblocks span {
                font-size: 14px; } }
    #page .page-grid .page-wrap .page-img {
      max-width: 0%;
      float: right;
      padding: 10px 0 25px 25px; }
      @media screen and (max-width: 768px) {
        #page .page-grid .page-wrap .page-img {
          max-width: 100%;
          width: 100%;
          padding: 0 0 25px 0; } }
      #page .page-grid .page-wrap .page-img img {
        max-width: 100%; }
    #page .page-grid .page-wrap .page-text .page-img {
      max-width: 40%;
      background: #fafafa;
      float: right;
      margin: 10px 0 35px 35px;
      padding: 25px; }
      @media screen and (max-width: 768px) {
        #page .page-grid .page-wrap .page-text .page-img {
          max-width: 100%;
          width: 100%;
          padding: 25px;
          text-align: center; } }
      #page .page-grid .page-wrap .page-text .page-img img {
        max-width: 100%; }
        @media screen and (max-width: 768px) {
          #page .page-grid .page-wrap .page-text .page-img img {
            max-width: 400px;
            width: 100%; } }
    #page .page-grid .page-wrap .page-text div {
      margin-bottom: 35px; }
      @media screen and (min-width: 769px) and (max-width: 991px) {
        #page .page-grid .page-wrap .page-text div {
          margin-bottom: 25px; } }
      @media screen and (max-width: 768px) {
        #page .page-grid .page-wrap .page-text div {
          margin-bottom: 25px; } }
    #page .page-grid .page-wrap .page-text h2 {
      margin-bottom: 15px;
      font-size: 24px; }
      @media screen and (min-width: 769px) and (max-width: 991px) {
        #page .page-grid .page-wrap .page-text h2 {
          margin-bottom: 10px;
          font-size: 18px; } }
      @media screen and (max-width: 768px) {
        #page .page-grid .page-wrap .page-text h2 {
          font-size: 18px; } }
    #page .page-grid .page-wrap .page-text p {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px; }
      @media screen and (min-width: 769px) and (max-width: 991px) {
        #page .page-grid .page-wrap .page-text p {
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 7px; } }
      @media screen and (max-width: 768px) {
        #page .page-grid .page-wrap .page-text p {
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 7px; } }
    #page .page-grid .page-wrap .page-text ul {
      list-style: disc;
      padding-left: 50px; }

#product-page .page-grid .product-block {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 50px;
  margin: 50px auto; }
  @media screen and (min-width: 769px) and (max-width: 991px) {
    #product-page .page-grid .product-block {
      padding-right: 25px;
      padding-left: 25px; } }
  @media screen and (max-width: 768px) {
    #product-page .page-grid .product-block {
      padding-right: 15px;
      padding-left: 15px; } }
  #product-page .page-grid .product-block .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; }
    #product-page .page-grid .product-block .content .left {
      width: 50%;
      border: 1px solid #ccc;
      padding: 25px; }
      @media screen and (max-width: 768px) {
        #product-page .page-grid .product-block .content .left {
          width: 100%;
          max-width: 500px;
          text-align: center;
          margin: 0 auto 35px auto; } }
      #product-page .page-grid .product-block .content .left img {
        width: 100%; }
    #product-page .page-grid .product-block .content .right {
      width: 40%; }
      @media screen and (max-width: 768px) {
        #product-page .page-grid .product-block .content .right {
          max-width: 450px;
          width: 100%;
          margin: 0 auto; } }
      #product-page .page-grid .product-block .content .right h1 {
        font-family: PTSansRegular;
        line-height: 1.3;
        text-align: left;
        margin-bottom: 25px; }
      #product-page .page-grid .product-block .content .right .product-item-title {
        font-size: 18px;
        margin-right: 10px;
        color: #79787f; }
        @media screen and (min-width: 769px) and (max-width: 991px) {
          #product-page .page-grid .product-block .content .right .product-item-title {
            font-size: 14px; } }
        @media screen and (max-width: 768px) {
          #product-page .page-grid .product-block .content .right .product-item-title {
            font-size: 14px; } }
      #product-page .page-grid .product-block .content .right .product-price {
        margin-bottom: 15px; }
        @media screen and (min-width: 769px) and (max-width: 991px) {
          #product-page .page-grid .product-block .content .right .product-price {
            margin-bottom: 10px; } }
        @media screen and (max-width: 768px) {
          #product-page .page-grid .product-block .content .right .product-price {
            margin-bottom: 10px; } }
        #product-page .page-grid .product-block .content .right .product-price .price-number {
          color: #86ae34;
          font-weight: 900;
          font-size: 32px; }
          @media screen and (min-width: 769px) and (max-width: 991px) {
            #product-page .page-grid .product-block .content .right .product-price .price-number {
              font-size: 18px; } }
          @media screen and (max-width: 768px) {
            #product-page .page-grid .product-block .content .right .product-price .price-number {
              font-size: 18px; } }
        #product-page .page-grid .product-block .content .right .product-price .price-currency {
          font-size: 18px;
          color: #86ae34; }
          @media screen and (min-width: 769px) and (max-width: 991px) {
            #product-page .page-grid .product-block .content .right .product-price .price-currency {
              font-size: 14px; } }
          @media screen and (max-width: 768px) {
            #product-page .page-grid .product-block .content .right .product-price .price-currency {
              font-size: 14px; } }
      #product-page .page-grid .product-block .content .right .product-weight {
        margin-bottom: 15px; }
      #product-page .page-grid .product-block .content .right .product-count {
        margin-bottom: 25px;
        display: inline-block; }
        @media screen and (min-width: 769px) and (max-width: 991px) {
          #product-page .page-grid .product-block .content .right .product-count {
            margin-bottom: 15px; } }
        @media screen and (max-width: 768px) {
          #product-page .page-grid .product-block .content .right .product-count {
            margin-bottom: 15px; } }
        #product-page .page-grid .product-block .content .right .product-count .count-controller {
          display: inline-block;
          height: 40px; }
          #product-page .page-grid .product-block .content .right .product-count .count-controller .product-count-btn {
            display: inline-block;
            text-align: center;
            cursor: pointer;
            color: #79787f;
            background: #FAFAFA; }
          #product-page .page-grid .product-block .content .right .product-count .count-controller input {
            text-align: center;
            font-size: 18px; }
          #product-page .page-grid .product-block .content .right .product-count .count-controller .product-count-btn, #product-page .page-grid .product-block .content .right .product-count .count-controller input {
            height: 40px;
            width: 40px;
            line-height: 40px; }
            @media screen and (min-width: 769px) and (max-width: 991px) {
              #product-page .page-grid .product-block .content .right .product-count .count-controller .product-count-btn, #product-page .page-grid .product-block .content .right .product-count .count-controller input {
                height: 30px;
                width: 30px;
                line-height: 30px; } }
            @media screen and (max-width: 768px) {
              #product-page .page-grid .product-block .content .right .product-count .count-controller .product-count-btn, #product-page .page-grid .product-block .content .right .product-count .count-controller input {
                height: 30px;
                width: 30px;
                line-height: 30px; } }
      #product-page .page-grid .product-block .content .right .product-add-to-cart button {
        width: 236px; }
        @media screen and (min-width: 769px) and (max-width: 991px) {
          #product-page .page-grid .product-block .content .right .product-add-to-cart button {
            width: 184px; } }
        @media screen and (max-width: 768px) {
          #product-page .page-grid .product-block .content .right .product-add-to-cart button {
            width: 184px; } }

#product-page .page-grid .product-desc {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 50px;
  background: #f0f0f0;
  padding-top: 50px;
  padding-bottom: 50px; }
  @media screen and (min-width: 769px) and (max-width: 991px) {
    #product-page .page-grid .product-desc {
      padding-right: 25px;
      padding-left: 25px; } }
  @media screen and (max-width: 768px) {
    #product-page .page-grid .product-desc {
      padding-right: 15px;
      padding-left: 15px; } }
  #product-page .page-grid .product-desc .content {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 50px;
    background: #fff;
    padding: 25px 50px;
    -webkit-box-shadow: -10px -10px 14px -6px black;
    -moz-box-shadow: -10px -10px 14px -6px black;
    box-shadow: -10px -10px 14px -6px black;
    max-height: 400px;
    overflow-y: hidden; }
    @media screen and (min-width: 769px) and (max-width: 991px) {
      #product-page .page-grid .product-desc .content {
        padding-right: 25px;
        padding-left: 25px; } }
    @media screen and (max-width: 768px) {
      #product-page .page-grid .product-desc .content {
        padding-right: 15px;
        padding-left: 15px; } }
    #product-page .page-grid .product-desc .content div {
      margin-bottom: 35px; }
      @media screen and (min-width: 769px) and (max-width: 991px) {
        #product-page .page-grid .product-desc .content div {
          margin-bottom: 25px; } }
      @media screen and (max-width: 768px) {
        #product-page .page-grid .product-desc .content div {
          margin-bottom: 25px; } }
      #product-page .page-grid .product-desc .content div h2 {
        margin-bottom: 15px;
        font-size: 24px; }
        @media screen and (min-width: 769px) and (max-width: 991px) {
          #product-page .page-grid .product-desc .content div h2 {
            margin-bottom: 10px;
            font-size: 18px; } }
        @media screen and (max-width: 768px) {
          #product-page .page-grid .product-desc .content div h2 {
            font-size: 18px; } }
      #product-page .page-grid .product-desc .content div p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 10px; }
        @media screen and (min-width: 769px) and (max-width: 991px) {
          #product-page .page-grid .product-desc .content div p {
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 7px; } }
        @media screen and (max-width: 768px) {
          #product-page .page-grid .product-desc .content div p {
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 7px; } }
      #product-page .page-grid .product-desc .content div ul {
        list-style: disc;
        padding-left: 50px; }
  #product-page .page-grid .product-desc .product-desc-more {
    width: 100%;
    text-align: center;
    margin-top: 25px; }
    #product-page .page-grid .product-desc .product-desc-more button {
      width: 300px;
      margin: 0 auto; }
      @media screen and (max-width: 768px) {
        #product-page .page-grid .product-desc .product-desc-more button {
          width: 270px; } }

#product-page .page-grid .other-products {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 50px;
  margin: 75px auto 50px auto; }
  @media screen and (min-width: 769px) and (max-width: 991px) {
    #product-page .page-grid .other-products {
      padding-right: 25px;
      padding-left: 25px; } }
  @media screen and (max-width: 768px) {
    #product-page .page-grid .other-products {
      padding-right: 15px;
      padding-left: 15px; } }
  #product-page .page-grid .other-products.big-margin {
    margin-top: 150px; }
  #product-page .page-grid .other-products h2 {
    text-align: center;
    margin-bottom: 50px;
    color: #505050;
    font-family: PacificoRegular;
    font-size: 32px; }
  #product-page .page-grid .other-products .content {
    cursor: grab;
    padding-bottom: 35px; }
    #product-page .page-grid .other-products .content .item {
      text-align: center;
      width: 30%;
      display: flex;
      margin-bottom: 50px;
      border: 1px solid #ccc;
      padding: 10px 10px 25px 10px; }
      #product-page .page-grid .other-products .content .item .item-content {
        display: flex;
        flex-direction: column;
        margin: 0 auto; }
        #product-page .page-grid .other-products .content .item .item-content .item-img {
          flex: 1 0 auto; }
          #product-page .page-grid .other-products .content .item .item-content .item-img a img {
            max-width: 320px;
            width: 100%; }
            @media screen and (max-width: 500px) {
              #product-page .page-grid .other-products .content .item .item-content .item-img a img {
                max-width: 250px; } }
        #product-page .page-grid .other-products .content .item .item-content .item-name {
          font-weight: 400;
          font-size: 15px;
          line-height: 1.44;
          text-align: inherit;
          color: #333333;
          margin-bottom: 15px;
          margin-top: 15px;
          flex: 1 0 auto;
          min-height: 45px; }
          #product-page .page-grid .other-products .content .item .item-content .item-name a {
            color: #333333; }
          @media screen and (max-width: 768px) {
            #product-page .page-grid .other-products .content .item .item-content .item-name {
              font-size: 12px; } }
        #product-page .page-grid .other-products .content .item .item-content .item-price {
          color: #86ae34;
          font-style: normal;
          font-weight: 900;
          font-size: 20px;
          line-height: 1.3;
          margin-bottom: 15px; }
          @media screen and (max-width: 768px) {
            #product-page .page-grid .other-products .content .item .item-content .item-price {
              font-size: 14px; } }
          #product-page .page-grid .other-products .content .item .item-content .item-price .item-price-count {
            font-size: 24px;
            color: #86ae34;
            margin: 0 2px 0 10px;
            font-weight: bold; }
            @media screen and (max-width: 768px) {
              #product-page .page-grid .other-products .content .item .item-content .item-price .item-price-count {
                font-size: 18px;
                margin: 0 0px 0 3px; } }
          #product-page .page-grid .other-products .content .item .item-content .item-price .item-price-currency {
            color: #86ae34; }
        #product-page .page-grid .other-products .content .item .item-content .item-cart {
          text-align: center; }
          @media screen and (min-width: 769px) and (max-width: 991px) {
            #product-page .page-grid .other-products .content .item .item-content .item-cart button {
              width: 180px; } }
          @media screen and (max-width: 768px) {
            #product-page .page-grid .other-products .content .item .item-content .item-cart button {
              width: 140px;
              font-size: 12px;
              height: 30px; } }
  #product-page .page-grid .other-products .swiper-pagination {
    bottom: 0; }
    #product-page .page-grid .other-products .swiper-pagination .swiper-pagination-bullet {
      background: #79787f; }
    #product-page .page-grid .other-products .swiper-pagination .swiper-pagination-bullet-active {
      background: #86ae34; }

#registration .page-grid .registration-wrap {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 50px; }
  @media screen and (min-width: 769px) and (max-width: 991px) {
    #registration .page-grid .registration-wrap {
      padding-right: 25px;
      padding-left: 25px; } }
  @media screen and (max-width: 768px) {
    #registration .page-grid .registration-wrap {
      padding-right: 15px;
      padding-left: 15px; } }
  #registration .page-grid .registration-wrap .registration-anons {
    max-width: 800px;
    margin: 25px auto 0 auto; }
  #registration .page-grid .registration-wrap .registration-form-block {
    margin-bottom: 50px;
    position: relative; }
    #registration .page-grid .registration-wrap .registration-form-block .form-success {
      background-color: #f0f0f0;
      padding: 25px;
      text-align: center;
      max-width: 800px;
      margin: 25px auto; }
    #registration .page-grid .registration-wrap .registration-form-block form#regForm {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 800px;
      margin: 25px auto;
      flex-wrap: wrap; }
      #registration .page-grid .registration-wrap .registration-form-block form#regForm div {
        width: 45%; }
        @media screen and (max-width: 500px) {
          #registration .page-grid .registration-wrap .registration-form-block form#regForm div {
            width: 100%;
            margin: 0 auto; } }
        #registration .page-grid .registration-wrap .registration-form-block form#regForm div label {
          width: 100%;
          color: #505050;
          font-weight: normal; }
        #registration .page-grid .registration-wrap .registration-form-block form#regForm div.reg-btn-block {
          width: 100%;
          margin-top: 15px; }
          @media screen and (max-width: 500px) {
            #registration .page-grid .registration-wrap .registration-form-block form#regForm div.reg-btn-block {
              text-align: center; } }
          #registration .page-grid .registration-wrap .registration-form-block form#regForm div.reg-btn-block button {
            height: 45px;
            width: 45%; }
            @media screen and (max-width: 500px) {
              #registration .page-grid .registration-wrap .registration-form-block form#regForm div.reg-btn-block button {
                width: 100%; } }
            @media screen and (max-width: 768px) {
              #registration .page-grid .registration-wrap .registration-form-block form#regForm div.reg-btn-block button {
                height: 38px;
                font-size: 12px; } }
    #registration .page-grid .registration-wrap .registration-form-block .form-loading {
      display: none;
      position: absolute;
      top: 0;
      transform: translate(-50%);
      left: 50%;
      width: 100%;
      background: rgba(240, 240, 240, 0.3);
      text-align: center;
      height: 100%;
      margin: auto; }
      #registration .page-grid .registration-wrap .registration-form-block .form-loading #floatBarsG {
        position: relative;
        width: 234px;
        top: 50%;
        height: 28px;
        margin: auto;
        transform: translateY(-50%); }

#reviews .page-grid .reviews-wrap {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 50px;
  max-width: 900px;
  margin: 25px auto 0 auto; }
  @media screen and (min-width: 769px) and (max-width: 991px) {
    #reviews .page-grid .reviews-wrap {
      padding-right: 25px;
      padding-left: 25px; } }
  @media screen and (max-width: 768px) {
    #reviews .page-grid .reviews-wrap {
      padding-right: 15px;
      padding-left: 15px; } }
  #reviews .page-grid .reviews-wrap .reviews-anons {
    margin: 25px auto 0 auto; }
  #reviews .page-grid .reviews-wrap .form-success {
    margin-top: 50px; }
  #reviews .page-grid .reviews-wrap .reviews-send {
    text-align: center;
    margin: 25px auto; }
  #reviews .page-grid .reviews-wrap #review-toggle-block {
    position: relative;
    margin-top: 50px;
    display: none; }
    #reviews .page-grid .reviews-wrap #review-toggle-block .take-review-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      align-items: baseline; }
      #reviews .page-grid .reviews-wrap #review-toggle-block .take-review-wrap .take-review-item {
        width: 45%; }
        @media screen and (max-width: 768px) {
          #reviews .page-grid .reviews-wrap #review-toggle-block .take-review-wrap .take-review-item {
            width: 100%;
            max-width: 400px;
            margin-bottom: 50px; }
            #reviews .page-grid .reviews-wrap #review-toggle-block .take-review-wrap .take-review-item:last-child {
              margin-bottom: 0; } }
        #reviews .page-grid .reviews-wrap #review-toggle-block .take-review-wrap .take-review-item .take-review-title {
          font-size: 18px;
          margin-bottom: 25px; }
        #reviews .page-grid .reviews-wrap #review-toggle-block .take-review-wrap .take-review-item form .review-point {
          padding: 10px 0; }
        #reviews .page-grid .reviews-wrap #review-toggle-block .take-review-wrap .take-review-item form label {
          width: 100%;
          color: #505050;
          font-weight: normal; }
        #reviews .page-grid .reviews-wrap #review-toggle-block .take-review-wrap .take-review-item form input.inpt-default {
          margin-bottom: 0; }
        #reviews .page-grid .reviews-wrap #review-toggle-block .take-review-wrap .take-review-item form textarea {
          padding: 10px 20px;
          background-color: #fff;
          border-color: #c9cdcf;
          border-radius: 3px;
          color: #9ba0a3;
          width: 100%;
          outline: none;
          height: 200px;
          resize: none; }
          @media screen and (max-width: 768px) {
            #reviews .page-grid .reviews-wrap #review-toggle-block .take-review-wrap .take-review-item form textarea {
              padding: 9px 10px; } }
        #reviews .page-grid .reviews-wrap #review-toggle-block .take-review-wrap .take-review-item form .review-level {
          margin-left: 15px; }
        #reviews .page-grid .reviews-wrap #review-toggle-block .take-review-wrap .take-review-item form .fa {
          font-size: 21px;
          color: #86ae34;
          cursor: pointer; }
        #reviews .page-grid .reviews-wrap #review-toggle-block .take-review-wrap .take-review-item .middle-point-block .middle-point-number {
          text-align: center;
          color: #86ae34;
          border: 1px solid #ccc;
          width: 220px;
          background: #f0f0f0;
          height: 120px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap; }
          #reviews .page-grid .reviews-wrap #review-toggle-block .take-review-wrap .take-review-item .middle-point-block .middle-point-number .review-point {
            font-size: 50px; }
          #reviews .page-grid .reviews-wrap #review-toggle-block .take-review-wrap .take-review-item .middle-point-block .middle-point-number .review-row {
            width: 100%; }
        #reviews .page-grid .reviews-wrap #review-toggle-block .take-review-wrap .take-review-item .middle-point-block .middle-point-img {
          margin-top: 53px;
          text-align: center;
          color: #86ae34;
          border: 1px solid #ccc;
          width: 220px;
          background: #f0f0f0;
          height: auto;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          padding: 25px 10px; }
          @media screen and (max-width: 768px) {
            #reviews .page-grid .reviews-wrap #review-toggle-block .take-review-wrap .take-review-item .middle-point-block .middle-point-img {
              display: none; } }
          #reviews .page-grid .reviews-wrap #review-toggle-block .take-review-wrap .take-review-item .middle-point-block .middle-point-img .review-row {
            width: 100%; }
            #reviews .page-grid .reviews-wrap #review-toggle-block .take-review-wrap .take-review-item .middle-point-block .middle-point-img .review-row img {
              max-width: 70px;
              width: 100%; }
          #reviews .page-grid .reviews-wrap #review-toggle-block .take-review-wrap .take-review-item .middle-point-block .middle-point-img .review-text {
            font-weight: bold;
            margin-top: 15px;
            font-size: 18px; }
  #reviews .page-grid .reviews-wrap .reviews-list {
    margin-top: 50px; }
    #reviews .page-grid .reviews-wrap .reviews-list .review-block {
      border: 5px solid #f0f0f0;
      padding: 10px; }
      #reviews .page-grid .reviews-wrap .reviews-list .review-block:not(:last-child) {
        margin-bottom: 25px; }
      #reviews .page-grid .reviews-wrap .reviews-list .review-block .review-block-wrap .review-head {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-items: baseline;
        flex-wrap: wrap;
        margin-bottom: 20px;
        border-bottom: 1px solid #ddd;
        padding-bottom: 5px; }
        #reviews .page-grid .reviews-wrap .reviews-list .review-block .review-block-wrap .review-head .review-username .review-level {
          color: #86ae34;
          margin-left: 15px; }

#search .page-grid .search-header {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 25px auto 0 auto; }
  @media screen and (min-width: 769px) and (max-width: 991px) {
    #search .page-grid .search-header {
      padding-right: 25px;
      padding-left: 25px; } }
  @media screen and (max-width: 768px) {
    #search .page-grid .search-header {
      padding-right: 15px;
      padding-left: 15px; } }
  #search .page-grid .search-header .left {
    width: 50%; }
    @media screen and (max-width: 768px) {
      #search .page-grid .search-header .left {
        width: 100%; } }
  #search .page-grid .search-header .right {
    width: 50%;
    text-align: right; }
    @media screen and (max-width: 768px) {
      #search .page-grid .search-header .right {
        display: none; } }
    #search .page-grid .search-header .right form input {
      height: 38px;
      padding: 0 10px;
      width: 300px;
      transform: translateX(4px); }
      @media screen and (min-width: 769px) and (max-width: 991px) {
        #search .page-grid .search-header .right form input {
          width: 250px; } }
    #search .page-grid .search-header .right form button {
      height: 38px;
      background: #679509;
      width: 100px;
      color: #fff;
      text-transform: uppercase;
      letter-spacing: 1.3px;
      transition: all .7s;
      border: 1px solid transparent; }
      @media screen and (min-width: 769px) and (max-width: 991px) {
        #search .page-grid .search-header .right form button {
          width: 70px; } }
      #search .page-grid .search-header .right form button:hover {
        background: #f0f0f0;
        color: #679509; }

#search .page-grid .content {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 50px auto 0 auto; }
  @media screen and (min-width: 769px) and (max-width: 991px) {
    #search .page-grid .content {
      padding-right: 25px;
      padding-left: 25px; } }
  @media screen and (max-width: 768px) {
    #search .page-grid .content {
      padding-right: 15px;
      padding-left: 15px; } }
  #search .page-grid .content .products-not-found {
    width: 100%;
    padding: 50px 10px; }
  #search .page-grid .content .item {
    text-align: center;
    width: 30%;
    display: flex;
    margin-bottom: 50px;
    border: 1px solid #ccc;
    padding: 10px 10px 25px 10px; }
    @media screen and (max-width: 768px) {
      #search .page-grid .content .item {
        width: 45%; } }
    @media screen and (max-width: 500px) {
      #search .page-grid .content .item {
        width: 90%;
        max-width: 340px;
        margin: 0 auto 50px auto; } }
    #search .page-grid .content .item .item-content {
      display: flex;
      flex-direction: column;
      margin: 0 auto; }
      #search .page-grid .content .item .item-content .item-img {
        flex: 1 0 auto; }
        #search .page-grid .content .item .item-content .item-img a img {
          max-width: 320px;
          width: 100%; }
          @media screen and (max-width: 500px) {
            #search .page-grid .content .item .item-content .item-img a img {
              max-width: 250px; } }
      #search .page-grid .content .item .item-content .item-name {
        font-weight: 400;
        font-size: 15px;
        line-height: 1.44;
        text-align: inherit;
        color: #333333;
        margin-bottom: 15px;
        margin-top: 15px;
        flex: 1 0 auto; }
        #search .page-grid .content .item .item-content .item-name a {
          color: #333333; }
      #search .page-grid .content .item .item-content .item-price {
        color: #86ae34;
        font-style: normal;
        font-weight: 900;
        font-size: 20px;
        line-height: 1.3;
        margin-bottom: 15px; }

#search .page-grid .pagination-block {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 50px;
  width: 100%; }
  @media screen and (min-width: 769px) and (max-width: 991px) {
    #search .page-grid .pagination-block {
      padding-right: 25px;
      padding-left: 25px; } }
  @media screen and (max-width: 768px) {
    #search .page-grid .pagination-block {
      padding-right: 15px;
      padding-left: 15px; } }
  #search .page-grid .pagination-block .pagination {
    margin: 75px auto 25px auto;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center; }
    @media screen and (min-width: 769px) and (max-width: 991px) {
      #search .page-grid .pagination-block .pagination {
        margin: 50px auto 25px auto; } }
    @media screen and (max-width: 768px) {
      #search .page-grid .pagination-block .pagination {
        margin: 50px auto 25px auto; } }
    #search .page-grid .pagination-block .pagination .nav_active {
      color: #ffffff;
      background-color: #86ae34;
      border-color: #86ae34;
      line-height: 43px;
      display: inline-block;
      min-width: 45px;
      height: 45px;
      padding: 0 2px;
      text-align: center;
      text-decoration: none;
      -webkit-transition: .3s;
      -o-transition: .3s;
      transition: .3s;
      margin-bottom: 5px;
      margin-right: 8px;
      border: 1px solid;
      font-size: 16px; }
      @media screen and (min-width: 769px) and (max-width: 991px) {
        #search .page-grid .pagination-block .pagination .nav_active {
          line-height: 39px;
          min-width: 40px;
          height: 40px; } }
      @media screen and (max-width: 768px) {
        #search .page-grid .pagination-block .pagination .nav_active {
          line-height: 34px;
          min-width: 35px;
          height: 35px; } }
    #search .page-grid .pagination-block .pagination a {
      color: #a5a5a5;
      border-color: #86ae34;
      line-height: 43px;
      display: inline-block;
      min-width: 45px;
      height: 45px;
      padding: 0 2px;
      text-align: center;
      text-decoration: none;
      -webkit-transition: .3s;
      -o-transition: .3s;
      transition: .3s;
      margin-bottom: 5px;
      border: 1px solid;
      font-size: 16px; }
      @media screen and (min-width: 769px) and (max-width: 991px) {
        #search .page-grid .pagination-block .pagination a {
          line-height: 39px;
          min-width: 40px;
          height: 40px; } }
      @media screen and (max-width: 768px) {
        #search .page-grid .pagination-block .pagination a {
          line-height: 34px;
          min-width: 35px;
          height: 35px; } }
      #search .page-grid .pagination-block .pagination a:hover {
        color: #ffffff;
        background-color: #86ae34;
        border-color: #86ae34; }
      #search .page-grid .pagination-block .pagination a:not(:last-child) {
        margin-right: 8px; }
