@import "partials/params";
@import "partials/mixins";


/* global params */

@font-face {
    font-family: PacificoRegular; /* Имя шрифта */
    src: url(fonts/Pacifico-Regular.ttf); /* Путь к файлу со шрифтом */
}

@font-face {
    font-family: PTSansRegular; /* Имя шрифта */
    src: url(fonts/PTSans/PTSans-Regular.ttf); /* Путь к файлу со шрифтом */
}
@font-face {
    font-family: 'Linearicon'; /* Имя шрифта */
    src: url(fonts/linearicons.ttf); /* Путь к файлу со шрифтом */
}

body{
	font-family: PTSansRegular, sans-serif;
	@include my-clean();
	background: $bg-white;
}

ul, ol{
	@include my-clean();
	@include my-list();
}
a{
	@include my-clean();
	@include my-links();
}

a:hover, a:focus, a:active, a:visited {
  text-decoration: none;
}

p, h1, h2, h3, h4, h5, h6{
	@include my-clean();
}

.no-scroll{
  overflow: hidden;
}



h1{
    font-weight: 400;
    font-size: 32px;
    line-height: 1.94;
    color: #4f4f4f;
    font-family: PacificoRegular;
    text-align: center;

    @media screen and (min-width: 992px) and (max-width: 1200px) {
       font-size: 28px;
    }

    @media screen and (min-width: 769px) and (max-width: 991px) {
       font-size: 24px;
    }

    @media screen and (max-width: 769px){
      font-size: 24px;
    }

}


.form-error{
    width: 100%!important;
    background: #f0f0f0!important;
    padding: 25px!important;
    display: none;
    p{

    }
}

.form-success{
    display: none;
    background-color: #f0f0f0;
    padding: 25px;
    text-align: center;
    p{
       margin-bottom: 25px;
    }

    div{
      margin-top: 25px;
    }
}
    
.phone-checker-red{
  border-color: #EA7C60!important;
}
    

.product-desc-full{
  max-height: none!important; 
}


.my-container{
  	@include my-container();
}

button{
  outline: none;
  border: none;

  &.btn-gray{
    height: 35px;
    padding: 0 15px;
    background: #f0f0f0;
    transition: all .7s;

    &:hover{
      background: #101010;
      color: #fff;
    }

  }
}




input{
	@include my-input()
}


.page-grid{
  margin: 25px 0 75px 0;

  @media screen and (max-width: 768px){
    margin: 25px 0 50px 0;
  }
}

.no-products{
  margin: 75px auto 50px auto;
  @media screen and (max-width: 768px){
      margin: 50px auto 25px auto;
  }

  p{
    text-align: center; 
    font-size: 24px;
    @media screen and (max-width: 768px){
      font-size: 18px;
    }
    
  }
}


.text-gray{
  color: #a5a5a5;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7;
  font-family: PTSansRegular, sans-serif;
  letter-spacing: 0em;
  @media screen and (max-width: 768px){
    font-size: 14px;
  }
}
    

::-webkit-input-placeholder {@include my-input-placeholder();}
::-moz-placeholder          {@include my-input-placeholder();}/* Firefox 19+ */
:-moz-placeholder           {@include my-input-placeholder();}/* Firefox 18- */
:-ms-input-placeholder      {@include my-input-placeholder();}

/* global params */


.zoom{

  cursor: move;
}

.dropdown:hover > .dropdown-menu { 
    display: block; 
} 

.swiper-pagination{

          .swiper-pagination-bullet{
            background: $color-menu-gray;
          }
          .swiper-pagination-bullet-active{
            background: $color-green;
          }
}

/* add-to-cart */

.window-bottom-add-to-cart{
  background: #414141;
  color: #fff;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 350px;
  z-index: 99999;
  padding: 15px 25px 10px 25px;
  display: none;
  
  @media screen and (max-width: 768px){
    width: 300px;
    padding: 15px 10px 10px 10px;
  }
  

  .close-bottom-add-to-cart{
        position: absolute;
        top: -2px;
        left: -26px;
        color: #414141;
        cursor: pointer;
        font-size: 18px;
  }
  
  .content{

  @include my-flex;

    .left{
      a{
        i{
          font-size: 45px;
          color: #fff;
        }

        &:hover i{
          color: $color-green;
        }
      }
      
    }

    .right{
      p{

      }
    }
  }
}






/* .carousel.fading */


.carousel.fade {
  opacity: 1;
}
.carousel.fade .item {
  -moz-transition: opacity ease-in-out .7s;
  -o-transition: opacity ease-in-out .7s;
  -webkit-transition: opacity ease-in-out .7s;
  transition: opacity ease-in-out .7s;
  left: 0 !important;
  opacity: 0;
  top:0;
  position:absolute;
  width: 100%;
  display:block !important;
  z-index:1;
}
.carousel.fade .item:first-child {
  top:auto;
  position:relative;
}
.carousel.fade .item.active {
  opacity: 1;
  -moz-transition: opacity ease-in-out .7s;
  -o-transition: opacity ease-in-out .7s;
  -webkit-transition: opacity ease-in-out .7s;
  transition: opacity ease-in-out .7s;
  z-index:2;
}


input.inpt-default{
  padding: 10px 20px;
  background-color: #fff;
  border-color: #c9cdcf;
  border-radius: 3px;
  color: #9ba0a3;
  width: 100%;
  margin-bottom: 25px; 
  font-size: 18px;

   @media screen and (max-width: 768px){
        font-size: 14px;
        padding: 9px 10px;
        margin-bottom: 15px;
   }
}


input.inpt-default:focus{
  box-shadow: inset 0 0 0 1px #e3e3e3;
}


button.btn-animation{
          width: 205px;
          background-color: #86ae34;
          color: #fff;
          font-weight: 400;
            font-size: 16px;
            font-family: PTSansRegular, sans-serif;
            border: none;
            outline: none;
            height: 40px;
            text-transform: uppercase;
            transform: translateY(0);

            @media screen and (min-width: 769px) and (max-width: 991px) {
               font-size: 14px;
                height: 35px;
            }

            @media screen and (max-width: 768px){
                font-size: 14px;
                height: 35px;
            }


            &:after{
              content: '';
              position: absolute;
              width: 0%;
              height: 100%;
              top: 0;
              left: 0;
              -webkit-transition: all .5s;
              -o-transition: all .5s;
              transition: all .5s;
              background-clip: content-box;
              z-index: -1;
              pointer-events: none;
            background-color: #1e1d24;
            }

            &:hover{
                  opacity: 1;
                  &:after{
                    width: 100%;
                  }
            }

          &:active(:after){
              height: 100%;
              width: 100%;
              top: 0;
              left: 0;
          }



}




.page-preloader-cover{position:fixed;z-index:1200;top:0;left:0;display:block;width:100%;height:100%;background-color:#fff}.page-preloader-cover svg{display:block;width:258px;height:258px;position:absolute;left:50%;top:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}.tree{fill:#679509}.circle-mask{-webkit-transform-origin:50% 90%;-ms-transform-origin:50% 90%;transform-origin:50% 90%;-webkit-animation:scale 5s infinite ease-out;animation:scale 5s infinite ease-out}@-webkit-keyframes scale{0%,100%{-webkit-transform:scale(0);transform:scale(0)}7%,90%{-webkit-transform:scale(.4);transform:scale(.4)}50%{-webkit-transform:scale(1);transform:scale(1)}}@keyframes scale{0%,100%{-webkit-transform:scale(0);transform:scale(0)}7%,90%{-webkit-transform:scale(.4);transform:scale(.4)}50%{-webkit-transform:scale(1);transform:scale(1)}}#toTop{display:none;text-decoration:none;position:fixed;bottom:20px;right:-999em;overflow:hidden;width:44px;height:44px;z-index:999;text-align:center;border-radius:50%}
/* .carousel.fading */



.header-small-fixed{
    position: fixed;
    z-index: 999999999999999999999999999;
    background: rgba(103,149,9, 0.9)!important;
    width: 100%;
    
    .content{
      height: 40px!important;
    }

    #header-mobile-logo{
      height: 40px!important;

      .logo{
        height: 35px!important;
      }
    }
}



.form-error{
          padding: 15px !important;
          margin-bottom: 15px;
}


.form-loading{
        display: none;
        position: absolute;
          top: 0;
          transform: translate(-50%);
          left: 50%;
          width: 100%;
          background: rgba(240,240,240,0.3);
          text-align: center;
          height: 100%;
          margin: auto;

        #floatBarsG{
          position: relative;
            width: 234px;
            top: 50%;
            height: 28px;
            margin: auto;
            transform: translateY(-50%);
        }
}



.breadcrumps{
    background-color: #f0f0f0;
    /*margin-top: 15px;*/
    .content{
      @include my-container();
      @include my-flex;
      padding-top: 10px;
      padding-bottom: 10px;
      .left{
        
         @media screen and (max-width: 768px){
          display: none;
         }
        a{
         color: #a5a5a5;
        }
      }
      .right{
        text-align: right;

        @media screen and (max-width: 768px){
           text-align: left;
         }

        a{
         color: #a5a5a5;
        }
        span.br-active{
          color: #4f4f4f;

        }

        .deliver{
          margin: 0 5px;
        }
      }
    }
  }

  
  .modal {
      @media screen and (max-width: 768px){
        top: 50px!important;
      }
  }

  #myModalLogin, #holidaysGraphic{

    padding-right: 0!important;

    .modal-content{
      .modal-header{
        h4{
          font-family: PacificoRegular;
          text-align: center;
          font-size: 28px;
          
          @media screen and (max-width: 768px){
            font-size: 24px;
          }
              
        }
      }
    }
    .modal-body{
      form#enterForm{
        width: 400px;
        margin: 25px auto;


        @media screen and (max-width: 768px){
          width: 250px;
          margin: 10px auto;
        }
        .enterField{
          input{
              
          }

          button#enterSubmit{
                width: 100%;
                height: 45px;

                 @media screen and (max-width: 768px){
                   height: 37px;
                 }
          }
        }
      
        .form-error{
          padding: 15px !important;
          margin-bottom: 15px;
        }
        
      }

      .form-loading{
        display: none;
        position: absolute;
          top: 0;
          transform: translate(-50%);
          left: 50%;
          width: 100%;
          background: rgba(240,240,240,0.3);
          text-align: center;
          height: 100%;
          margin: auto;

        #floatBarsG{
          position: relative;
            width: 234px;
            top: 50%;
            height: 28px;
            margin: auto;
            transform: translateY(-50%);
        }
      }


      .user-cabinet{
        margin: 25px auto;
        .content{
          @include my-flex;
          flex-wrap: wrap;
          justify-content: space-around;
          .left{

            @media screen and (max-width: 500px) {

              width: 100%;
              margin-bottom: 25px;
              padding-bottom: 25px;
              max-width: 280px;
              border-bottom: 1px solid #ccc;
            }
            p{

            }

            div{
              margin-top: 15px;
            }
          }
          .right{

            @media screen and (max-width: 500px) {

              width: 100%;
              max-width: 280px;
            }

            p{

            }

            div{
              margin-top: 15px;
            }

          }
        }
      }
      
    }
    .modal-footer{

       font-weight: 400;
        font-size: 16px;
        line-height: 1.7;
        font-family: PTSansRegular, sans-serif;
        letter-spacing: 0em;
        text-align: center;

      span{
        color: #a5a5a5;
        margin-right: 10px;
      }
      a{
        color: $color-green;
      }
    }

  }




/* ==============     css load animations ===================*/ 


/* ==============     css load animation 1 ===================*/ 
  #floatBarsG{
  position:relative;
  width:234px;
  height:28px;
  margin:auto;
}

.floatBarsG{
  position:absolute;
  top:0;
  background-color:rgb(102,149,9);
  width:28px;
  height:28px;
  animation-name:bounce_floatBarsG;
    -o-animation-name:bounce_floatBarsG;
    -ms-animation-name:bounce_floatBarsG;
    -webkit-animation-name:bounce_floatBarsG;
    -moz-animation-name:bounce_floatBarsG;
  animation-duration:1.5s;
    -o-animation-duration:1.5s;
    -ms-animation-duration:1.5s;
    -webkit-animation-duration:1.5s;
    -moz-animation-duration:1.5s;
  animation-iteration-count:infinite;
    -o-animation-iteration-count:infinite;
    -ms-animation-iteration-count:infinite;
    -webkit-animation-iteration-count:infinite;
    -moz-animation-iteration-count:infinite;
  animation-direction:normal;
    -o-animation-direction:normal;
    -ms-animation-direction:normal;
    -webkit-animation-direction:normal;
    -moz-animation-direction:normal;
  transform:scale(.3);
    -o-transform:scale(.3);
    -ms-transform:scale(.3);
    -webkit-transform:scale(.3);
    -moz-transform:scale(.3);
}

#floatBarsG_1{
  left:0;
  animation-delay:0.6s;
    -o-animation-delay:0.6s;
    -ms-animation-delay:0.6s;
    -webkit-animation-delay:0.6s;
    -moz-animation-delay:0.6s;
}

#floatBarsG_2{
  left:29px;
  animation-delay:0.75s;
    -o-animation-delay:0.75s;
    -ms-animation-delay:0.75s;
    -webkit-animation-delay:0.75s;
    -moz-animation-delay:0.75s;
}

#floatBarsG_3{
  left:58px;
  animation-delay:0.9s;
    -o-animation-delay:0.9s;
    -ms-animation-delay:0.9s;
    -webkit-animation-delay:0.9s;
    -moz-animation-delay:0.9s;
}

#floatBarsG_4{
  left:88px;
  animation-delay:1.05s;
    -o-animation-delay:1.05s;
    -ms-animation-delay:1.05s;
    -webkit-animation-delay:1.05s;
    -moz-animation-delay:1.05s;
}

#floatBarsG_5{
  left:117px;
  animation-delay:1.2s;
    -o-animation-delay:1.2s;
    -ms-animation-delay:1.2s;
    -webkit-animation-delay:1.2s;
    -moz-animation-delay:1.2s;
}

#floatBarsG_6{
  left:146px;
  animation-delay:1.35s;
    -o-animation-delay:1.35s;
    -ms-animation-delay:1.35s;
    -webkit-animation-delay:1.35s;
    -moz-animation-delay:1.35s;
}

#floatBarsG_7{
  left:175px;
  animation-delay:1.5s;
    -o-animation-delay:1.5s;
    -ms-animation-delay:1.5s;
    -webkit-animation-delay:1.5s;
    -moz-animation-delay:1.5s;
}

#floatBarsG_8{
  left:205px;
  animation-delay:1.64s;
    -o-animation-delay:1.64s;
    -ms-animation-delay:1.64s;
    -webkit-animation-delay:1.64s;
    -moz-animation-delay:1.64s;
}



@keyframes bounce_floatBarsG{
  0%{
    transform:scale(1);
    background-color:rgb(102,149,9);
  }

  100%{
    transform:scale(.3);
    background-color:rgb(255,255,255);
  }
}

@-o-keyframes bounce_floatBarsG{
  0%{
    -o-transform:scale(1);
    background-color:rgb(102,149,9);
  }

  100%{
    -o-transform:scale(.3);
    background-color:rgb(255,255,255);
  }
}

@-ms-keyframes bounce_floatBarsG{
  0%{
    -ms-transform:scale(1);
    background-color:rgb(102,149,9);
  }

  100%{
    -ms-transform:scale(.3);
    background-color:rgb(255,255,255);
  }
}

@-webkit-keyframes bounce_floatBarsG{
  0%{
    -webkit-transform:scale(1);
    background-color:rgb(102,149,9);
  }

  100%{
    -webkit-transform:scale(.3);
    background-color:rgb(255,255,255);
  }
}

@-moz-keyframes bounce_floatBarsG{
  0%{
    -moz-transform:scale(1);
    background-color:rgb(102,149,9);
  }

  100%{
    -moz-transform:scale(.3);
    background-color:rgb(255,255,255);
  }
}

/* ==============     css load animation 1 ===================*/ 






/* ==============     css load animations ===================*/ 





