@import "partials/params";
@import "partials/mixins";




#product-page{

	.page-grid{
		.product-block{
			@include my-container();
			margin: 50px auto;
			.content{
				@include my-flex;
				flex-wrap: wrap;
				.left{
					width: 50%;
					border: 1px solid #ccc;
					padding: 25px;
					
					 @media screen and (max-width: 768px){
					 	width: 100%;
					    max-width: 500px;
					    text-align: center;
					    margin: 0 auto 35px auto;
					 }
					img{
						width: 100%;
					}
				}

				.right{
					width: 40%;
					@media screen and (max-width: 768px){
					 	max-width: 450px;
					    width: 100%;
					    margin: 0 auto;
					 }
					h1{
						font-family: PTSansRegular;
						line-height: 1.3;
						text-align: left;
						margin-bottom: 25px;
						
					}

					.product-item-title{
							font-size: 18px;
    						margin-right: 10px;
    						color: $color-menu-gray;
    						@media screen and (min-width: 769px) and (max-width: 991px) {
					       		font-size: 14px;
					    	}

					    	 @media screen and (max-width: 768px){
					    	 	font-size: 14px;
					    	 }

						}

					.product-price{
						margin-bottom: 15px;

						@media screen and (min-width: 769px) and (max-width: 991px) {
					       margin-bottom: 10px;
					    }

					    @media screen and (max-width: 768px){
					    	 	margin-bottom: 10px;
					    }

						.price-number{
							color: $color-green;
							font-weight: 900;
    						font-size: 32px;

    						@media screen and (min-width: 769px) and (max-width: 991px) {
    							font-size: 18px;
    						}

    						@media screen and (max-width: 768px){
    							font-size: 18px;
    						}
						}

						.price-currency{
							font-size: 18px;
							color: $color-green;
							@media screen and (min-width: 769px) and (max-width: 991px) {
    							font-size: 14px;
    						}
    						@media screen and (max-width: 768px){
    							font-size: 14px;
    						}
						}
					}

					.product-weight{
						margin-bottom: 15px;
					}

					.product-count{
						margin-bottom: 25px;
    					display: inline-block;
						
						@media screen and (min-width: 769px) and (max-width: 991px) {
    						margin-bottom: 15px;
    					}

    					@media screen and (max-width: 768px){
    						margin-bottom: 15px;
    					}

						.count-controller{
							display: inline-block;
							height: 40px;

							.product-count-btn{

								display: inline-block;
							    text-align: center;
								cursor: pointer;
								color: #79787f;
								background: #FAFAFA;

							}

							input{

							    text-align: center;
							    font-size: 18px;
							}	

							.product-count-btn, input{
								height: 40px;
							    width: 40px;
							    line-height: 40px;
								@media screen and (min-width: 769px) and (max-width: 991px) {
								    height: 30px;
								    width: 30px;
								    line-height: 30px;
								}
								@media screen and (max-width: 768px){
									height: 30px;
								    width: 30px;
								    line-height: 30px;
								}
							}
							
						}
						
					}

					.product-add-to-cart{
						button{
							width: 236px;
							@media screen and (min-width: 769px) and (max-width: 991px) {
								width: 184px;
							}

							@media screen and (max-width: 768px){
								width: 184px;
							}
						}
					}
				}
			}
		}

		.product-desc{
			@include my-container();
			background: #f0f0f0;
			padding-top: 50px;
			padding-bottom: 50px;

			.content{

				@include my-container();
				background: #fff;
    			padding: 25px 50px;
    			-webkit-box-shadow: -10px -10px 14px -6px rgba(0,0,0,1);
				-moz-box-shadow: -10px -10px 14px -6px rgba(0,0,0,1);
				box-shadow: -10px -10px 14px -6px rgba(0,0,0,1);
				max-height: 400px;
    			overflow-y: hidden;
				
				div{
					margin-bottom: 35px;

					@media screen and (min-width: 769px) and (max-width: 991px) {
						margin-bottom: 25px;
					}

					@media screen and (max-width: 768px){
							margin-bottom: 25px;
					}

					h2{
						margin-bottom: 15px;
						font-size: 24px;
						@media screen and (min-width: 769px) and (max-width: 991px) {
							margin-bottom: 10px;
    						font-size: 18px;
						}

						@media screen and (max-width: 768px){
							font-size: 18px;
						}
					}

					p{
						font-size: 16px;
	    				line-height: 24px;
	    				margin-bottom: 10px;

	    				@media screen and (min-width: 769px) and (max-width: 991px) {
							    font-size: 14px;
							    line-height: 21px;
							    margin-bottom: 7px;
						}

						@media screen and (max-width: 768px){
							font-size: 14px;
							    line-height: 21px;
							    margin-bottom: 7px;
						}
					}

					ul{
						list-style: disc;
						padding-left: 50px;
						li{
				
						}

					}

				}
			}

			.product-desc-more{
				width: 100%;
				text-align: center;
				margin-top: 25px;
				button{
					width: 300px;
					margin: 0 auto;
					
					@media screen and (max-width: 768px){
						width: 270px;
					}
				}
			}
		}

		.other-products{
			
			@include my-container();
			margin: 75px auto 50px auto;

			&.big-margin{
				margin-top: 150px;
			}

			h2{
				text-align: center;
			    margin-bottom: 50px;
			    color: #505050;
			    font-family: PacificoRegular;
			    font-size: 32px;
			}

			.content{

				cursor: grab;
				padding-bottom: 35px;

					.item{
						
						text-align: center;
						width: 30%;
						display: flex;
						margin-bottom: 50px;
						border: 1px solid #ccc;
		    			padding: 10px 10px 25px 10px;
						
						.item-content{

							display: flex;
	    					flex-direction: column;
	    					margin: 0 auto;

							.item-img{
								flex: 1 0 auto;

								a{
									img{
										max-width: 320px;
										width: 100%;

										@media screen and (max-width: 500px) {
											max-width: 250px;
										}
									}
								}
							}

							.item-name{
								font-weight: 400;
							    font-size: 15px;
							    line-height: 1.44;
							    text-align: inherit;
							    color: #333333;
							    margin-bottom: 15px;
							    margin-top: 15px;
							    flex: 1 0 auto;
							    min-height: 45px;
							    a{
							    	color: #333333;
							    }

								@media screen and (max-width: 768px){
									font-size: 12px;
								}
							}

							.item-price{
								color: #86ae34;
							    font-style: normal;
							    font-weight: 900;
							    font-size: 20px;
							    line-height: 1.3;
							    margin-bottom: 15px;

								@media screen and (max-width: 768px){
									font-size: 14px;
								}

								.item-price-title{

								}

								.item-price-count{
									font-size: 24px;
									color: $color-green;
									margin: 0 2px 0 10px;
									font-weight: bold;
									@media screen and (max-width: 768px){
										font-size: 18px;
										margin: 0 0px 0 3px;
									}
								}
								.item-price-currency{
									color: $color-green;
								}

							}

							.item-cart{
									text-align: center;
									button{
										 @media screen and (min-width: 769px) and (max-width: 991px){
										    width: 180px;
										}
										@media screen and (max-width: 768px){
											width: 140px;
											font-size: 12px;
											 height: 30px;
										}
									}
							}

					}
				}
			}

			.swiper-pagination{

					bottom: 0;

					.swiper-pagination-bullet{
						background: $color-menu-gray;
					}
					.swiper-pagination-bullet-active{
						background: $color-green;
					}
			}

		}


		
	}
}