@import "partials/params";
@import "partials/mixins";




#blog{
	

	.page-grid{

			@include my-container();

			margin: 25px auto 0 auto;

			h1{

			}

			.blog-anons{
				max-width: 800px;
				margin: 25px auto 0 auto;
				
				@media screen and (max-width: 768px){
					margin: 10px auto 0 auto;
				}
				
				.text-gray{

				}
			}

			.blog-wrap{

				@include my-flex();
				 align-items: unset;
				 flex-wrap: wrap;
				 margin: 50px auto;

				.blog-item{
					width: 30%;
					display: flex;
					margin-bottom: 50px;
					.blog-item-content{
						display: flex;
						flex-direction: column;
						.blog-img{
							img{
								max-width: 100%;
							}
							    margin-bottom: 10px;
						}
						.blog-date{
							    margin-bottom: 15px;
							   
							span{
								color: $color-menu-gray;
								font-weight: bold;
							}
						}
						.blog-title{
							margin-bottom: 10px;
							

						    a{
						    	font-size: 18px;
						    	font-weight: 600;
						    	color: #4f4f4f;

						    	@media screen and (max-width: 768px){
						   			font-size: 16px;
						   		}

						    	&:hover{
						    		color: #86ae34;
						    	}
						    }
						}
						.blog-text{
							color: #8b8b8b;
							margin-bottom: 20px;
	    					font-size: 16px;
							
	    					@media screen and (max-width: 768px){
							   	
							   	font-size: 14px;
						   	}
						}
						.blog-more{
							button{
								    background-color: #86ae34;
								    color: #fff;
								    width: 160px;
								    font-weight: 400;
								    font-size: 16px;
								    line-height: 1.75;
								    transition: all .7s;
								    height: 32px;

									@media screen and (max-width: 768px){
							   			font-size: 14px;
							   			width: 140px;
							   			height: 30px;
						   			}
	    
							    &:hover{
							    	    background-color: #1e1d24;
							    }
							}
						}
					}


					@media screen and (max-width: 991px){
		   				width: 45%;
		   			}

		   			@media screen and (max-width: 500px){
		   				width: 100%;
		   			}
				}
		}
	}
}