@import "partials/params";
@import "partials/mixins";




#reviews{
	
	.page-grid{
		
		.reviews-wrap{
			
			@include my-container();
			max-width: 900px;
			margin: 25px auto 0 auto;

			h1{

			}

			.reviews-anons{
				margin: 25px auto 0 auto;
			}

			.form-success{
				margin-top: 50px;
			}

			.reviews-send{

				text-align: center;
   				margin: 25px auto;

			}

			#review-toggle-block{
				
				position: relative;
				margin-top: 50px;
				display: none;

				.take-review-wrap{
					
					@include my-flex();
					flex-wrap: wrap;
					align-items: baseline;

					.take-review-item{

						width: 45%;

						@media screen and (max-width: 768px){
							width: 100%;
						    max-width: 400px;
						    margin-bottom: 50px;

						    &:last-child{
						    	margin-bottom: 0;
						    }
						}

						.take-review-title{
							font-size: 18px;
							margin-bottom: 25px;
						}

						form{
							
							.review-point{
								padding: 10px 0;
							}
							label{
								width: 100%;
							    color: #505050;
							    font-weight: normal;
							}
							input.inpt-default{
								margin-bottom: 0;
							}
							textarea{
								padding: 10px 20px;
							    background-color: #fff;
							    border-color: #c9cdcf;
							    border-radius: 3px;
							    color: #9ba0a3;
							    width: 100%;
							    outline: none;
							    height: 200px;
							    resize: none;

							    @media screen and (max-width: 768px){
							    	padding: 9px 10px;
							    }
							}
							
							.review-level{
								margin-left: 15px;
							}
							
							.fa{
								font-size: 21px;
    							color: #86ae34;
    							cursor: pointer;
							}
							
						}

						.middle-point-block{
							.middle-point-number{
								text-align: center;
								color: #86ae34;
								border: 1px solid #ccc;
							    width: 220px;
							    background: #f0f0f0;
							    height: 120px;
							    @include my-flex();
							    flex-wrap: wrap;



							    .review-point{
							    	font-size: 50px;
							    }
								.review-row{
									width: 100%;
								}

								#review-level-middle{
									i{

									}
								}
							}

							.middle-point-img{
								margin-top: 53px;
								text-align: center;
								color: #86ae34;
								border: 1px solid #ccc;
							    width: 220px;
							    background: #f0f0f0;
							    height: auto;
							    @include my-flex();
							    flex-wrap: wrap;
							    padding: 25px 10px;

							    @media screen and (max-width: 768px){
							    	display: none;
							    }

							    .review-row{
							    	width: 100%;
							    	img{
										max-width: 70px;
										width: 100%;
									}
							    }
								
								.review-text{
									font-weight: bold;
							    	margin-top: 15px;
							    	font-size: 18px;
								}
							    
							}
						}
					}

				}
			}



			.reviews-list{

				margin-top: 50px;

				.review-block{

					border: 5px solid #f0f0f0;
					padding: 10px;

					&:not(:last-child){
						margin-bottom: 25px;
					}

					.review-block-wrap{

						.review-head{

							width: 100%;
							@include my-flex();
							align-items: baseline;
							flex-wrap: wrap;
							margin-bottom: 20px;
						    border-bottom: 1px solid #ddd;
						    padding-bottom: 5px;
							.review-username{
								.review-level{
									color: #86ae34;
									margin-left: 15px;
								}
							}
							.review-date{

							}
						}
						.review-text{

						}
					}
				}
			}

		}
	}
}