@import "partials/params";
@import "partials/mixins";




.wrapper{
	
	margin: 0 auto;
	background: $bg-white;
	overflow: hidden;
    position: relative;
	
	 @media screen and (max-width: 768px) {
	 	 margin-top: 85px;
	 }
   
}


#header{
	
		@media screen and (max-width: 768px) {
			display: none;
			
		}

	.search-block{
			
		    position: absolute;
		    right: 0;
		    top: 0;
		    /*transform: translateX(-50%);*/
		    z-index: 999999999999999;
		    display: none;
		    

			.search-close{
			    position: absolute;
			    top: 0;
			    left: 25px;
			    background: transparent;
			    /* width: 100px; */
			    color: #fff;
			    @media screen and (min-width: 769px) and (max-width: 991px) {
			    	left: 0;
			    }
			  }

			form{
				@include my-container();
				max-width: 800px;
				input{
			    width: 500px;
			    position: relative;
			    z-index: 99999999999;
			    height: 38px;
			    padding: 0 10px;
			    transform: translateX(4px);

				    @media screen and (min-width: 769px) and (max-width: 991px) {
				    	width: 315px;
				    }
			  	}

			  	button.search-btn{
					height: 38px;
				    background: #f0f0f0;
				    width: 100px;
				    color: #679509;
				    text-transform: uppercase;
				    letter-spacing: 1.3px;
				    transition: all .7s;
					border: 1px solid transparent;
				    &:hover{
				    	background: #679509;
				    	color: #fff;
				    	border: 1px solid #fff;
				    }
			  	}
			}  	
	}

	.header-top{
		background: $bg-green;
		color: $color-white;
	   
	.content{
		@include my-flex;
		@include my-container();
		position: relative;
		
		.left{
		padding: 9px 0;
			span{
				margin-right: 10px;
			}
			strong{
				letter-spacing: 1.1px;
			}
		}
		.right{
		padding: 9px 0;
		position: relative;
			ul{
				li{
					display: inline-block;
					position: relative;

					.dropdown-menu{
						margin-top: 11px;
						padding-top: 0;
    					border: 1px solid #ccc;
						border-radius: 0;
						min-width: 140px;
						
						.divider{
							margin: 0;
						}

						li{
							width: 100%;
							
							&.dropdown-active a{
								background: #679509;
    							color: #fff;
							}

							&:after{
						    	content: none;
							}

							a{
								background: #fff;
    							color: #679509;
    							padding-top: 7px;
    							padding-bottom: 7px;

    							&:hover{
    								background: #f0f0f0;
    							}
							}
						}
					}

					a{
						@include my-flex;

						color: #f0f0f0;
					    text-transform: uppercase;
					    padding-right: 17px;
					    padding-left: 17px;
					    font-size: 12px;
					    transition: all .7s;

					    @media screen and (min-width: 769px) and (max-width: 991px) {
							    padding-right: 8px;
    							padding-left: 10px;

					    }

					    &:hover{
					    	text-decoration: none;
					    	color: #c7ed79;
					    }

					    i{
					   		font-size: 18px;
    						margin-right: 8px;

    						&.shopping-cart-active{
    							color: #c7ed79!important;
    						}

    						@media screen and (min-width: 769px) and (max-width: 991px) {
	    						font-size: 16px;
	    						margin-right: 5px;
    						}

    						

					    }

					    .cart-items-count-wrap{

				    	    text-transform: lowercase;
							font-weight: bold;
							color: #c7ed79;

					    	.cart-items-count-number{
								margin: 0 1px;
					    	}
					    }


					}

					&:last-child{
						a{
						padding-right: 0;	
						}
					}

					&:after{
						    content: "";
						    width: 1.5px;
						    height: 22px;
						    display: inline-block;
						    background: #fff;
						    position: absolute;
						    top: 0;
							@media screen and (min-width: 769px) and (max-width: 991px) {
						   	 height: 18px;
							}

					}
				}
			}

			
		}



	  }

	  

	}


	.header-bottom{
		@include my-flex;
		@include my-container();

		.left{
			padding: 15px 0;
			a{
				img.logo{
					width: 150px;
				}
			}
			
		}

		.right{
			ul{
				li{
					display: inline-block;
					margin-left: 15px;
					margin-right: 15px;
					position: relative;

					@media screen and (min-width: 769px) and (max-width: 991px) {
						margin-left: 10px;
						margin-right: 10px;
					}

					&:last-child{
						margin-right: 0;
					}
					a{
						display: inline-block;
						color: #a5a5a5;
						text-transform: uppercase;
						font-weight: 600;
					    font-size: 14px;
					    line-height: 1.643;
					    letter-spacing: 0.04em;
					    text-decoration: none;
					    transition: all .7s;




					    &:hover{
					    	color: #86ae34;
					    }

					    &:before{
					    	content: '\e82d';
						    display: inline-block;
						    font-family: 'Linearicon';
						    line-height: 1;
						    speak: none;
						    text-transform: none;
						    -webkit-font-smoothing: antialiased;
						    -moz-osx-font-smoothing: grayscale;
						    line-height: 1.5;
						    position: absolute;
						    top: -16px;

    						right: -20px;
						    width: 100%;
						    opacity: 0;
						    color: #86ae34;
						    -webkit-transform: rotate(0deg) scaleX(1) scaleY(1);
						    -ms-transform: rotate(0deg) scaleX(1) scaleY(1);
						    transform: rotate(0deg) scaleX(1) scaleY(1);
					    }

					    &:after{
					    	content: '\e82d';
						    display: block !important;
						    line-height: 1.5;
						    display: inline-block;
						    font-family: 'Linearicon';
						    line-height: 1;
						    speak: none;
						    text-transform: none;
						    -webkit-font-smoothing: antialiased;
						    -moz-osx-font-smoothing: grayscale;
						    position: absolute;
						    right: calc(100% - 20px);
    						top: -7px;
    						font-size: 10px;
						    width: 100%;
						    opacity: 0;
						    -webkit-transform: translate(0px, 0px) scaleX(-0.9) scaleY(0.9) skewX(-5deg) skewY(5deg);
						    -ms-transform: translate(0px, 0px) scaleX(-0.9) scaleY(0.9) skewX(-5deg) skewY(5deg);
						    transform: translate(0px, 0px) scaleX(-0.9) scaleY(0.9) skewX(-5deg) skewY(5deg);
					    }

					    &.active{
					    	color: #86ae34;
					    	&:before{
					    	opacity: 1;
						    color: #86ae34;
					    	}

					    	&:after{
					    	opacity: 1;
						    color: #86ae34;
					    	}
					    }

					    &:hover{
					    	color: #86ae34;
					    	&:before{
					    	opacity: 1;
						    color: #86ae34;
					    	}

					    	&:after{
					    	opacity: 1;
						    color: #86ae34;
					    	}
					    }
				}

				ul.dropdown-menu{

					max-width: 300px;
					li{
						width: 100%;
						margin: 0;
						a{
							width: 100%;
							    padding: 3px 20px 3px 40px;
							&:after{
								right: calc(100% - 24.5px);
    							top: 21.5px;
							}
							&:before{
								
    							top: 4px;
    							right: -14px;
							}
						}
					}
				}
			}
		}
		
	}

  }	
}


#header-mobile{
	display: none;
	width: 100%;
	background: $bg-green;
	padding-top: 5px;
	padding-bottom: 5px;
	position: fixed;
	z-index: 9999999;
	top: 0;
	left: 0;


	@media screen and (max-width: 768px) {
		display: block;
	}
	@include my-container();

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

	.content{
		@include my-flex();
		height: 50px;
		transition: all .7s;
		.item{

			a{
				
				display: inline-block;
				img.logo{
					height: 40px;
				}

				&#header-mobile-logo{
					background: #fff;
    				padding: 0 15px;
    				height: 50px;
				}
			}

			
			i#mobile-header-open{
				cursor: pointer;
			    font-size: 25px;
			   color: #fff;
			}
    

			i#mobile-header-cart{
				
				color: #fff;
				font-size: 40px;
				line-height: 30px;
			}
		}
	}
}



#header-mobile-panel{
	display: none;
	position: fixed;
    top: 40px;
    z-index: 9999;
    height: 100vh;
    background: #679509;
    width: 300px;
    overflow: auto;
    padding-bottom: 50px;

	.content{
		@include my-container();
		padding-top: 25px;
    	padding-bottom: 25px;

		.settings{
			@include my-flex();

			span{
				display: inline-block;
			    width: 100%;
			    margin-bottom: 5px;
			    color: #fff;
				font-family: PTSansRegular;
			}

			select{
				padding: 3px;
			}

			.left{

			}
    	 	.right{

    	 	}
		}

		.search-mobile{
			margin-bottom: 25px;
			form{

				input{
					height: 30px;
				    padding: 5px;
				    font-size: 14px;
				}
				button{
					height: 30px;
					font-size: 14px;
					background: #86ae34;
				    color: #fff;
				    border: 1px solid #fff;
				    margin-left: 7px;
				}

			}
		}


		.cats-mobile{
			margin-bottom: 25px;
			.cats-mobile-title{
				font-family: PacificoRegular;
				color: #fff;
				font-size: 18px;
				margin-bottom: 10px;
			}
			ul{
				list-style: circle;
			    padding-left: 16px;
			    color: #fff;
				li{

					margin-bottom: 5px;
					a{
						color: #fff;
					}
				}
			}
		}

		.pages-mobile{
			margin-bottom: 25px;
			.pages-mobile-title{
				font-family: PacificoRegular;
				color: #fff;
				font-size: 18px;
				margin-bottom: 10px;
			}

			ul{
				display: flex;
				flex-wrap: wrap;
				li{

					margin: 0 15px 10px 0;
					a{
						color: #fff;
					}
				}
			}
		}

	}
    
}
