@import "partials/params";
@import "partials/mixins";



/*======= main page ========*/
#carousel-wrap{
	.content{
		@include my-flex;
		@include my-container();
		.left{
			width: 270px;
			padding: 25px 10px 0 0;
			position: relative;
			
			@media screen and (min-width: 769px) and (max-width: 991px) {
    				
    			display: none;
    		}
    		@media screen and (max-width: 768px) {
    				
    			display: none;
    		}
			

			h2{
				font-family: PacificoRegular;
				margin-bottom: 25px;
			}

			ul#menu-cats{
				
				li{
					display: inline-block;
    				width: 100%;
					border: 1px solid #ccc;
    				border-bottom: none;
					&:last-child{
						border-bottom: 1px solid #ccc;
					}
					a{
						padding: 5px 10px;
						display: inline-block;
    					width: 100%;
						color: $color-menu-gray;
						text-decoration: none;
						font-size: 16px;

						&:hover{
							color: #fff;
							background: $bg-green;
						}
					}
				}
				/*
				&:before{
					content: "";
				    position: absolute;
				    right: 5px;
				    width: 1.2px;
				    top: 20px;
				    height: 100%;
				    background-color: #e3e3e3;
				    z-index: 9;
				}*/
				
			}
		}
		.right{
			width: calc( 100% - 270px);

			@media screen and (min-width: 769px) and (max-width: 991px) {
    			margin-top: 25px;	
    			width: 100%;
    		}
    		@media screen and (max-width: 768px) {
    			margin-top: 25px;	
    			width: 100%;
    		}

			#carousel{
				height: 250px;

				 @media screen and (max-width: 768px){
			    	height: auto;
			    }
				
				.carousel-inner{
					.item{
						img{
							width: auto;
    						height: 250px;
    						float: right;

    						@media screen and (max-width: 768px){
						    	width: 100%;
							    height: auto;
							    max-width: 600px;
						    }
							
							@media screen and (min-width: 769px) and (max-width: 991px) {
    						    float: none;
    							margin: 0 auto;
    						}
    						@media screen and (max-width: 768px) {
    						    float: none;
    							margin: 0 auto;
    						}
						}
					}
				}

				.carousel-indicators{
					bottom: -50px;
					li{
						background: $bg-green;
						border: 1px solid $bg-green;
						border-radius: 0;
						opacity: 0.7;
						&.active{
							opacity: 1;
						}
					}
				}
			}
		}
	}
}


#random-products{
		
		@include my-container();

		margin-top: 100px;

		h2{
				text-align: center;
			    margin-bottom: 50px;
			    color: #505050;
			    font-family: PacificoRegular;
			    font-size: 32px;

			    @media screen and (max-width: 500px) {

			    	display: none;
			    }
			}

			.content{

				cursor: grab;
				padding-bottom: 0;

					.item{
						
						text-align: center;
						width: 30%;
						display: flex;
						margin-bottom: 50px;
						border: 1px solid #ccc;
		    			padding: 10px 10px 25px 10px;


						@media screen and (max-width: 500px) {
							
						}
		    			
						
						.item-content{


	    					margin: 0 auto;

							.item-img{

								a{
									img{
										max-width: 320px;
										width: 100%;

										@media screen and (max-width: 500px) {
											max-width: 250px;
										}
									}
								}
							}

							.item-name{
								font-weight: 400;
							    font-size: 15px;
							    line-height: 1.44;
							    text-align: inherit;
							    color: #333333;
							    margin-bottom: 15px;
							    margin-top: 15px;
							    min-height: 45px;
							    a{
							    	color: #333333;
							    }

								@media screen and (max-width: 768px){
									font-size: 12px;
								}
							}

							.item-price{
								color: #86ae34;
							    font-style: normal;
							    font-weight: 900;
							    font-size: 20px;
							    line-height: 1.3;
							    margin-bottom: 15px;

								@media screen and (max-width: 768px){
									font-size: 14px;
								}

								.item-price-title{

								}

								.item-price-count{
									font-size: 24px;
									color: $color-green;
									margin: 0 2px 0 10px;
									font-weight: bold;
									@media screen and (max-width: 768px){
										font-size: 18px;
										margin: 0 0px 0 3px;
									}
								}
								.item-price-currency{
									color: $color-green;
								}

							}

							.item-cart{
									text-align: center;
									button{
										 @media screen and (min-width: 769px) and (max-width: 991px){
										    width: 180px;
										}
										@media screen and (max-width: 768px){
											width: 140px;
											font-size: 12px;
											 height: 30px;
										}
									}
							}

					}
				}
			}

			.swiper-pagination{

					bottom: 0;

					.swiper-pagination-bullet{
						background: $color-menu-gray;
					}
					.swiper-pagination-bullet-active{
						background: $color-green;
					}
			}		
}


#why-we{
	background: url(images/bg-gray.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    margin: 75px 0 0px 0;
    padding: 100px 0 100px 0;



    @media screen and (max-width: 768px){
    	padding: 50px 0 25px 0;
    	margin: 100px 0 50px 0;
    	 background: #F1F1F1;
    }

     @media screen and (max-width: 500px){
		   margin: 50px 0 50px 0;
		}
   	h2{
   		color: $color-green;
   		font-family: PacificoRegular;
   		text-align: center;
   		word-spacing: 5px;

   		@media screen and (max-width: 768px){
   			font-size: 24px;
   		}
   	}

   	.content{

   		@include my-flex;
		@include my-container();

		margin-top: 50px;
		
		    align-items: end;
		        flex-wrap: wrap;

		@media screen and (max-width: 500px){
		    margin-top: 35px;
		}
   		.items{
   			padding: 15px;
   			width: 30%;
   			@media screen and (max-width: 500px){
   				width: 100%;
   				margin-bottom: 25px;
   				padding: 0;
   			}
   			.top{
   				text-align: center;
   				margin-bottom: 35px;
   				@media screen and (max-width: 500px){
		    		margin-bottom: 15px;
				}
				img{
					
					margin: 0 auto;
					@media screen and (max-width: 768px){
					height: 40px;
					}
				}
   			}
   			.center{
				text-align: center;
   				font-weight: 400;
			    font-size: 20px;
			    line-height: 1.45;
			    font-family: PacificoRegular;
			    letter-spacing: 0.02em;
			    color: #4f4f4f;
				margin-bottom: 25px;
				@media screen and (max-width: 768px){
					font-size: 18px;
					}
				@media screen and (max-width: 500px){
		    		margin-bottom: 15px;
				}
   			}
   			.bottom{
			    color: #8b8b8b;
			    text-align: justify;
   			}
   		}
   	}
}


#our-products{

	margin: 75px 0 50px 0;

	@media screen and (min-width: 992px) and (max-width: 1200px) {
		margin: 50px 0 50px 0;
	}

	@media screen and (min-width: 769px) and (max-width: 991px) {
		margin: 25px 0 25px 0;
	}

	    

    
    @media screen and (max-width: 768px){
    	margin: 25px 0 25px 0;
    }
 

	h2{
 		font-family: PacificoRegular;
 		text-align: center;
 		margin-bottom: 35px;
 		@media screen and (max-width: 768px){
   			font-size: 24px;
   		}
	}

	.products-container{
		@include my-container();
		display: flex;
  		flex-wrap: wrap;

		.item-left{
			display: flex;
			width: 300px;
			padding-right: 0px;
			
			@media screen and (min-width: 992px) and (max-width: 1200px) {
				width: 250px;
			}
			

			@media screen and (min-width: 769px) and (max-width: 991px) {
				width: 200px;
			}

			@media screen and (max-width: 768px) {
    			width: 150px;
    		}

    		@media screen and (max-width: 500px){
		    	width: 100%;
		    max-width: 350px;
		    margin: 0 auto;
    		}

			.left{
			display: flex;
  			flex-direction: column;
  			width: 100%;
  			padding-right: 10px;
  			@media screen and (max-width: 768px) {
    			padding-right: 5px;
    		}
    		@media screen and (max-width: 500px){
		    	padding-right: 0px;
    		}

    		    

				.cat-block{
					
					a{
						width: 100%;
						img{
							width: 100%;
							max-width: 100%;
							transition: all .7s ease-in-out;
							-webkit-filter: grayscale(66%); 
							filter: grayscale(66%);

							@media screen and (max-width: 500px){
						    	width: 100%;
				    		}

							&:hover{
						  	 	-webkit-filter: grayscale(0%); 
	  						 	filter: grayscale(0%); 
	  						 	transform: translateY(-5px);
	  						 	-webkit-box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.75);
								-moz-box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.75);
								box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.75);
							} 
						}
					}
					&:not(:last-child){
						margin-bottom: 10px;
						@media screen and (max-width: 768px) {
			    			margin-bottom: 5px;
			    		}

					}

					@media screen and (max-width: 500px){
				    	margin-bottom: 25px!important;
		    		}
				}
			}
		}

		.item-right{
			display: flex;
			width: calc(100% - 300px);

			@media screen and (min-width: 992px) and (max-width: 1200px) {
				width: calc(100% - 250px);
			}

			@media screen and (min-width: 769px) and (max-width: 991px) {
				width: calc(100% - 200px);
			}

			@media screen and (max-width: 768px) {
    			width: calc(100% - 150px);
    		}

    		@media screen and (max-width: 500px){
		    	width: 100%;
		    	max-width: 350px;
		    	margin: 0 auto;
    		}


			.right{
				display: flex;
  				flex-direction: column;
  				position: relative;
				
				.content{
					
					@include my-flex;
					flex-wrap: wrap;
					.cat-block{

						width: 100%;
						display: flex;
						justify-content: space-between;
						margin-bottom: 10px;

						@media screen and (max-width: 768px) {
			    			margin-bottom: 5px;
			    		}

						@media screen and (max-width: 500px){
					
							flex-wrap: wrap;
							margin-bottom: 25px;
						}
						a{
								width: 100%;
							img{
								width: 100%;
								transition: all .7s ease-in-out;
						  		-webkit-filter: grayscale(66%); 
						 		 filter: grayscale(66%);
						  		&:hover{
						  	 	-webkit-filter: grayscale(0%); 
	  						 	filter: grayscale(0%); 
	  						 	transform: translateY(-5px);
	  						 	-webkit-box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.75);
								-moz-box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.75);
								box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.75);
								}
							}
						}

						.cat-subblock{
							width: calc(50% - 5px);

							@media screen and (max-width: 768px) {
			    				width: calc(50% - 2.5px);
			    			}
							@media screen and (max-width: 500px){
							
								width: 100%;
							}

							&:not(:last-child){
								
								@media screen and (max-width: 500px){
									margin-bottom: 25px;
								}
							}

							a{
								img{
									width: 100%;
									transition: all .7s ease-in-out;
						  			-webkit-filter: grayscale(66%); 
						  			filter: grayscale(66%);

							  		&:hover{
							  	 	-webkit-filter: grayscale(0%); 
		  						 	filter: grayscale(0%); 
		  						 	transform: translateY(-5px);
		  						 	-webkit-box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.75);
									-moz-box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.75);
									box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.75);
									}
					  			}
							}
							
						}

						&.center{
							
						    position: relative;

						}

						&.bottom{
							position: relative;
    						
						}
					}
				}
				
			}
		}
	}
   
}




#blog-on-main{
	
	background: url("images/bg-gray.jpg");
   	background-repeat: no-repeat;
   	background-size: cover;
   	margin: 25px 0 0px 0;
    padding: 100px 0 150px 0;
    @media screen and (max-width: 768px){
    		    padding: 50px 0 25px 0;
    		    margin: 75px 0 50px 0;
    		    background: #F1F1F1;
    }

	.content{
		
		@include my-container();


		h2{
			text-align: center;
			margin-bottom: 50px;
			color: #505050;
			font-family: PacificoRegular;
			font-size: 32px;
			@media screen and (max-width: 768px){
   			font-size: 24px;
   			}
		}

		.blog-wrap{

			@include my-flex();
			 align-items: end;
			 flex-wrap: wrap;

			.blog-container{
				width: 30%;

				@media screen and (max-width: 500px){
					width: 100%;
					max-width: 350px;
					margin: 0 auto;
					margin-bottom: 50px;
				}
				.blog-img{
					img{
						max-width: 100%;
					}
					    margin-bottom: 10px;
				}
				.blog-date{
					    margin-bottom: 15px;
					span{
						color: $color-menu-gray;
						font-weight: bold;
					}
				}
				.blog-title{
					margin-bottom: 10px;
					

				    a{
				    	font-size: 18px;
				    	font-weight: 600;
				    	color: #4f4f4f;

				    	@media screen and (max-width: 768px){
				   			font-size: 16px;
				   		}

				    	&:hover{
				    		color: #86ae34;
				    	}
				    }
				}
				.blog-text{
					color: #8b8b8b;
					 margin-bottom: 15px;
				}
				.blog-more{
					button{
						background-color: #86ae34;
    					color: #fff;
    					padding: 2px 9px;
    					font-weight: 400;
					    font-size: 12px;
					    line-height: 1.75;
					    transition: all .7s;

					    &:hover{
					    	    background-color: #1e1d24;
					    }
					}
				}
			}
		}
	}

}





#subscribe{
	padding: 50px 0;
	position: relative;

	@media screen and (min-width: 769px) and (max-width: 991px) {
		padding: 25px 0;
	}

	 @media screen and (max-width: 768px){
	 	padding: 35px 0 0 0;
	 }
	.content{

		@include my-container();

		h3{
			text-align: center;
			margin-bottom: 50px;
			color: #505050;
			font-family: PacificoRegular;
			font-size: 32px;

			@media screen and (max-width: 768px){
				font-size: 24px;
				margin-bottom: 25px;
			}
		}

		.form-block{
			text-align: center;
			form{
				margin: 0 auto;
				    display: flex;
    				justify-content: center;
				input{
					    width: 400px;
					    border-color: #e3e3e3;
					    color: #79787f;
					    box-shadow: inset 0 0 0 1px #fafafa;
					    padding-top: 14px;
					    padding-bottom: 14px;
					    padding: 9.5px 20px;
					    font-size: 18px;

					    @media screen and (max-width: 768px){
						 	max-width: 250px;
    						width: calc(100% - 60px);
    						padding: 9.5px 10px;
    						font-size: 14px;
						 }

					    &:focus{
    						border-color: #e3e3e3;
    						box-shadow: inset 0 0 0 1px #e3e3e3;
    					}
				}
				button{
					width: 140px;
					background-color: #86ae34;
					color: #fff;
					font-weight: 400;
    				font-size: 16px;
    				font-family: PTSansRegular, sans-serif;
    				border: none;
    				outline: none;
    				margin: 0 0 0 0px;
    				
    				padding: 0 3px;
    				text-transform: uppercase;

    				@media screen and (max-width: 768px){
						width: 40px;
					    margin: 0;
					   
					}

    				span{
    					display: inline-block;

						@media screen and (max-width: 769px) {
								display: none;
						}
    				}

    				i{
    					display: none;
						@media screen and (max-width: 769px) {
							display: inline-block;
						}
    				}
	
				}

				input, button{
					height: 46px;
					@media screen and (min-width: 769px) and (max-width: 991px) {
					height: 40px;
					}

					@media screen and (max-width: 768px){
					 	height: 40px;
					 }
				}
				
			}
		}
	}
}

