@import "partials/params";
@import "partials/mixins";


#footer{

	background: url(images/footer-bg.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	padding: 150px 0 50px 0;
	 @media screen and (max-width: 768px){
	 	padding: 100px 0 25px 0;
	 }
	.content{
		@include my-container();
		@include my-flex;
		flex-wrap: wrap;
		align-items: baseline;
		@media screen and (max-width: 768px){

		}
			.block{
		
				width: 30%;

				 @media screen and (max-width: 768px){
				 	width: 100%;
					 margin-top: 35px;
				 	&:not(:last-child){
				 		   
				 	}
				 }



				.block-title{
				    font-size: 20px;
				    line-height: 1.45;
				    font-family: PacificoRegular;
				    letter-spacing: 0.02em;
				    margin-bottom: 25px;
				}
				
				.block-content{
					ul{
						 @media screen and (max-width: 768px){
						 	display: flex;
    						flex-wrap: wrap;
						 }
						    
						li{
							margin-bottom: 10px;

							@media screen and (max-width: 768px){
								margin-right: 25px;
								margin-bottom: 15px!important;
							}


							a{
								color: #79787f;
								text-decoration: none;
								-webkit-transition: .2s;
							    -o-transition: .2s;
							    transition: .2s;
							    font-size: 15px;
							    font-weight: 300;

							    @media screen and (max-width: 768px){
							    	font-size: 14px;
							    }

							    &:hover{
							    	color: #1e1d24;
							    }
							}

							&:not(:last-child){
								margin-bottom: 10px;
							}
						}
					}

					.subblocks{
						

					    &:not(:last-child){
					    	margin-bottom: 10px;
					    }
					    
					    i{
					    	color: #679509;
					    	font-size: 20px;
					    	margin-right: 10px;
					    }

					    span{
					    	color: #79787f;
							text-decoration: none;
							-webkit-transition: .2s;
						    -o-transition: .2s;
						    transition: .2s;
						    font-size: 15px;
						    font-weight: 300;
						    @media screen and (max-width: 768px){
						    	font-size: 14px;
						    }
					    }
					}
				}
				
		}
	}
}