
// colors
$bg-green: #679509; //#79bb36;
$bg-white: #fff;
$bg-grey: #F1F1F1;

$color-white: #fff;
$color-menu-gray: #79787f;
$color-green: #86ae34;
$color-form-label: #505050;

// sizes
$info-block-left: 120px;
$text-font: 16px;
$container-padd: 50px;


