@import "partials/params";
@import "partials/mixins";




#page-not-found{
	
	.page-grid{
		
		@include my-container();
		margin: 25px auto 0 auto;

		h1{

		}
		.page-not-found-wrap{
			
			margin: 50px auto;

			@media screen and (max-width: 768px){

				margin: 25px auto;
			}
		
			.page-not-found-text{
					
					.page-img{
						max-width: 40%;
					    background: #fafafa;
					    float: right;
					    margin: 10px 0 35px 35px;
					    padding: 25px;

					    @media screen and (max-width: 768px){
							max-width: 100%;
							width: 100%;
							padding: 25px;
							text-align: center;
						}

						img{
							max-width: 100%;
							@media screen and (max-width: 768px){
								max-width: 400px;
								width: 100%;
							}
						}
					}
					
					div{
						margin-top: 35px;
						text-align: center;

						@media screen and (min-width: 769px) and (max-width: 991px) {
							margin-bottom: 25px;
						}

						@media screen and (max-width: 768px){
								margin-bottom: 25px;
						}
					}
					h2{
						margin-bottom: 15px;
						font-size: 24px;
						@media screen and (min-width: 769px) and (max-width: 991px) {
							margin-bottom: 10px;
    						font-size: 18px;
						}

						@media screen and (max-width: 768px){
							font-size: 18px;
						}
					}

					p{
						font-size: 16px;
	    				line-height: 24px;
	    				margin-bottom: 10px;
	    				text-align: center;

	    				@media screen and (min-width: 769px) and (max-width: 991px) {
							    font-size: 14px;
							    line-height: 21px;
							    margin-bottom: 7px;
						}

						@media screen and (max-width: 768px){
							font-size: 14px;
							    line-height: 21px;
							    margin-bottom: 7px;
						}
					}

					ul{
						list-style: disc;
						padding-left: 50px;
						li{
				
						}

					}
			}
		}
	}
}